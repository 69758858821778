import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ColDef, DomLayoutType } from 'ag-grid-community';
import { FaultLog, FaultLogItem } from 'src/app/utils/fault-log';

@Component({
  selector: 'app-fault-log-viewer',
  templateUrl: './fault-log-viewer.component.html',
  styleUrls: ['./fault-log-viewer.component.css']
})
export class FaultLogViewerComponent implements OnInit {

  @Input() crmtFile?: FaultLog

  public rowData!: FaultLogItem[]

  private edited = false

  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 10;

  public columnDefs: ColDef[] = [
    { headerName: "Date", field: 'loggedDate', width: 150, resizable: false },
    { headerName: "Time", field: 'loggedTime', width: 150, editable: false, resizable: true, sortable: true, filter: true },
    { headerName: "Fault Description", field: 'faultCode', width: 250, resizable: true, sortable: true, filter: true },
    { headerName: "Fault Class", field: 'faultClass', width: 150, resizable: true, sortable: true, filter: true }
  ];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    
    this.crmtFile = changes["crmtFile"].currentValue

    if(this.crmtFile) {

      this.rowData = this.crmtFile.entries
    }
  }

  public getData() {

    return {
      done: this.edited,
      fileType: 'fault-log',
      fileData: this.crmtFile
    }
  }
}
