<div class="wrapper">
    <ix-content-header
        variant="Secondary"
        headerTitle="Command Type"
        headerSubtitle="Select the type of command to send">
    </ix-content-header>

    <ix-select class="options" (itemSelectionChange)="typeChanged($event)" mode="single">
        <ix-select-item 
            *ngFor="let option of options"
            [label]="option.label" 
            [value]="option.value">
        </ix-select-item>
    </ix-select>

    <div *ngIf="selectedType=='activate-software'">
        <ix-content-header
            variant="Secondary"
            headerTitle="Version Number"
            headerSubtitle="Select the version number to activate">
        </ix-content-header>

        <ix-input-group style="margin-bottom: 0.5rem;">
            <div class="grid">
                <ix-layout-grid class="grid-layout">
                    <ix-row>
                        <ix-col size="6" class="grid-col">
                            <input class="version"
                            [formControl]="versionNumber"
                            placeholder="Version number" 
                            type="text" 
                            class="form-control"
                            [ngClass]="{
                                'range-error': versionNumber.invalid && versionNumber.touched
                            }" />
                        </ix-col>
                        </ix-row>
                        <ix-row>
                        <ix-col class="error-col">
                            <div *ngIf="versionNumber.invalid && versionNumber.touched">
                                <span class="error" *ngIf="versionNumber.errors?.['required']">Version number is a required field.</span>
                                <span class="error" *ngIf="versionNumber.errors?.['pattern']">Only numeric values are allowed.</span>
                                <span class="error" *ngIf="versionNumber.errors?.['minValue'] || versionNumber.errors?.['maxValue']">
                                  Version number should be in the range of 0-65535.
                                </span>
                              </div>
                        </ix-col>
                    </ix-row>
                </ix-layout-grid>
            </div>        
            
            
        </ix-input-group>
    </div>
    <div *ngIf="selectedType=='write-details'">
        <ix-content-header
            variant="Secondary"
            headerTitle="Cab Radio Details"
            headerSubtitle="Enter the vehicle information.">
        </ix-content-header>

        <ix-input-group style="margin-bottom: 0.5rem;">
            <div class="grid">
                <ix-layout-grid class="grid-layout">
                    <ix-row>
                        <ix-col size="6" class="grid-col">
                            <ix-select [formControl]="vehicleType" mode="single" value=false 
                                i18nPlaceholder="Vehicle Type"
                                [ngClass]="{
                                    'range-error': vehicleType.invalid && vehicleType.touched
                                }">
                                <ix-select-item 
                                    label="Engine" 
                                    value="0">
                                </ix-select-item>
                                <ix-select-item 
                                    label="Coach" 
                                    value="1">
                                </ix-select-item>
                            </ix-select>
                        </ix-col>
                    </ix-row>
                    <ix-row>
                        <ix-col class="error-col">
                            <div class="error" *ngIf="vehicleType.invalid && vehicleType.touched">
                                This field is required.
                              </div>
                        </ix-col>
                    </ix-row>
                    <ix-row>
                        <ix-col size="6" class="grid-col">
                            <input 
                            [formControl]="vehicleNumber"
                            placeholder="Vehicle number" 
                            type="text" 
                            class="form-control"
                            [ngClass]="{
                                'range-error': vehicleNumber.invalid && vehicleNumber.touched
                            }"/>
                        </ix-col>
                    </ix-row>
                    <ix-row>
                        <ix-col class="error-col">
                            <div *ngIf="vehicleNumber.invalid && vehicleNumber.touched">
                                <span class="error" *ngIf="versionNumber.errors?.['required'] && !vehicleNumber.errors?.['maxlength']">Vehicle number is a required field</span>
                                <span class="error" *ngIf="vehicleNumber.errors?.['maxlength']">Maximum 9 characters are allowed.</span>
                              </div>
                        </ix-col>
                    </ix-row>
                    <ix-row>
                        <ix-col size="6" class="grid-col">
                            <ix-select [formControl]="languageCode" mode="single" value=false 
                            i18nPlaceholder="Language Code"
                            [ngClass]="{
                                'range-error': languageCode.invalid && languageCode.touched
                            }">
                                <ix-select-item 
                                    label="English" 
                                    value="1">
                                </ix-select-item>
                                <ix-select-item *ngIf="!nrMode"
                                    label="Norwegian" 
                                    value="11">
                                </ix-select-item>
                            </ix-select>
                        </ix-col>
                    </ix-row>
                    <ix-row>
                        <ix-col class="error-col">
                            <div class="error" *ngIf="languageCode.invalid && languageCode.touched">
                                Language code is a required field.
                              </div>
                        </ix-col>
                    </ix-row>
                </ix-layout-grid>
            </div>         

            </ix-input-group>
        </div>

</div>

