import { Component, OnInit } from '@angular/core';
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {

  public name$!: Observable<string>;
  public email!: string
  public group!: string
  public organization!: string
  public description!: string

  constructor(private _apiService: ApiService) { }

  public ngOnInit(): void {
    // Get display name from cognito user attributes
    getCurrentUser().then(user => {
      if (user) {
        fetchUserAttributes().then(attributes => {
          this.name$ = new Observable(observer => {
            observer.next(attributes.given_name);
            observer.complete();
          });
        });
      }
    });

    this._apiService.test().subscribe(data => {
      this.email = data.message.email
      this.group = this.getUserLevel(data.message.group)
      this.organization = data.message.organization
    })
  }
  private getUserLevel(group: string){
    let userLevel = "NONE";
    if(group === "4"){
      userLevel = "Super user"
      this.description = "Siemens admin only."
    } else if(group === "3"){
      userLevel = "Privileged user"
      this.description = "Top level access - can add subgroups."
    } else if(group === "2"){
      userLevel = "Standard user"
      this.description = "Restricted access - can perform operations for maintenance and update."
    } else if(group === "1"){
      userLevel = "Readonly user"
      this.description = "No ability to modify any aspect of the system. View / download data only."
    }
    return userLevel;
  }
}
