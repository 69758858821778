import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridReadyEvent, GridApi, GridOptions } from 'ag-grid-community';
import { of, Subscription, switchMap } from 'rxjs';
import * as moment from 'moment'

export interface LogItem {
  timestamp: string;
  api: string;
  user: string;
  group: string;
  data: string;
  body: string;
}

@Component({
  selector: 'app-crmt-audit-log',
  templateUrl: './crmt-audit-log.component.html',
  styleUrls: ['./crmt-audit-log.component.css']
})
export class CrmtAuditLogComponent implements OnInit {

  private gridApi!: GridApi<LogItem>;
  gridOptions!: GridOptions;

  public columnDefs: ColDef[] = [
    { headerName: "Timestamp", field: 'timestamp', resizable: false, checkboxSelection: false, sortable: true, filter: true },
    { headerName: "API", field: 'api', resizable: true, sortable: true, filter: true },
    { headerName: "User", field: 'user', resizable: true, sortable: true, filter: true },
    { headerName: "Group", field: 'group', resizable: true },
    { headerName: "Data", field: 'data', resizable: true },
    { headerName: "Body", field: 'body', resizable: true },
  ];

  // enables pagination in the grid
  public pagination = true;

  // Set to `true` so that the number of rows to load per page is automatically adjusted by the grid
  public paginationAutoPageSize = true;

  // Data that gets displayed in the grid
  public rowData$!: LogItem[]
  private subscription?: Subscription
  selected: any = { startDate: null, endDate: null } ;
  alwaysShowCalendars: boolean;
  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  constructor(private _apiService: ApiService) {
    this.alwaysShowCalendars = true;
    this.gridOptions = <GridOptions>{
      overlayLoadingTemplate: '<div style="color: white; padding: 100px; width: 330px; height: 1800px; text-align: justify; font-size: 18px;">Loading...</div>',
      overlayNoRowsTemplate: '<div style="color: white; padding: 100px; width: 330px; height: 1800px; text-align: justify; font-size: 18px;">No rows...</div>'
    };
  }

  ngOnInit(): void { 
    // Set the startDate and endDate to today in UTC
    // So that on load one day data will be visible by default
    this.selected.startDate = moment()
    this.selected.endDate = moment()
  }

  onGridReady(params: GridReadyEvent<LogItem>) {
     this.gridApi = params.api;
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }


  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment()],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  isInvalidDate = (m: moment.Moment) => (!(m.valueOf() < moment().valueOf()))

  public toDisplayCustomDateData() {
    if (this.gridApi != null) {
      this.gridApi.showLoadingOverlay();
    }
    let startDate = Number(this.selected?.startDate)
    let endDate = Number(this.selected?.endDate)
    //When clear button is clicked OR start and end dates are exactly identicle then
    // fetch the data from start of the day to end of today
    if (startDate != null && endDate != null 
      && ((startDate == 0 && endDate == 0) || (startDate === endDate))) {
      const now = moment.utc();
      startDate = Number(moment.utc(now).startOf('day'));
      endDate = Number(moment.utc(now).endOf('day'));
    }
    this.subscription = this._apiService.getAuditLog(startDate, endDate).pipe(
      switchMap((logs: any[]) => {

          
        const mapped = logs.map((item, index) => {

          const newItem: LogItem = {
            timestamp: moment.utc(item.timestamp).local().format('YYYY-MM-DD HH:mm:ss'),
            api: item.api,
            user: item.user,
            group: item.group,
            data: item.data,
            body: item.body
          }

          return newItem
        })

        return of(mapped)
      })).subscribe((data: LogItem[]) => {
        if (data != null) {
          this.rowData$ = data
          this.gridApi.hideOverlay();
        }
        this.gridApi.showNoRowsOverlay();
      })

  }
}