<div class="wrapper">

    <div *ngIf="!isLoading && details; else spin">
        <ix-button 
            [disabled]="isDisabled"
            class="m-1" 
            variant="Primary" 
            [outline]="true"
            (click)="refreshJobStatus()">
            Refresh Job Status
        </ix-button>
        <ix-kpi label="Job status" [value]="status | titlecase"></ix-kpi>
        <ix-kpi 
            label="Succeeded" 
            [value]="details.numberOfSucceededThings" 
            unit="devices">
        </ix-kpi>

        <ix-kpi 
            label="Failed" 
            [value]="details.numberOfFailedThings" 
            unit="devices" 
            [state]="details.numberOfFailedThings == '0' ? 'neutral' : 'alarm'">
        </ix-kpi>

        <ix-kpi 
            label="In progress" 
            [value]="details.numberOfInProgressThings" 
            unit="devices">
        </ix-kpi>
        
        <ix-kpi 
            label="Queued" 
            [value]="details.numberOfQueuedThings" 
            unit="devices">
        </ix-kpi>

        <ix-kpi 
            label="Timed out" 
            [value]="details.numberOfTimedOutThings" 
            unit="devices">
        </ix-kpi>

        <ix-kpi 
            label="Removed" 
            [value]="details.numberOfRemovedThings" 
            unit="devices">
        </ix-kpi>

        <ix-kpi 
            label="Rejected" 
            [value]="details.numberOfRejectedThings" 
            unit="devices">
        </ix-kpi>

        <ix-kpi 
            label="Cancelled" 
            [value]="details.numberOfCanceledThings" 
            unit="devices">
        </ix-kpi>

        <div class = "device-list-container">
            <ix-content-header
                class="mid-page"
                width="100%"
                hasBackButton="false"
                headerTitle="Device List"
                headerSubtitle="Devices associated with the Job">
            </ix-content-header>
            <div>
                <ag-grid-angular
                  class="ag-theme-alpine-dark ag-theme-ix"
                  style="height: 100%; width: 100%;"
                  [columnDefs]="columnDefs"
                  [rowSelection]="'multiple'"
                  [animateRows]="true"
                  [defaultColDef]="defaultColDef"
                  (gridReady)="onGridReady($event)"
                  [domLayout]="domLayout"
                  [pagination]="pagination"
                  [paginationPageSize]="paginationPageSize"
                  [rowModelType]="'infinite'"
                  [cacheBlockSize]="paginationPageSize"
                  >
                </ag-grid-angular>
            </div>      
        </div>
    </div>

    <ng-template #spin>
        <div class="spinner">
            <p>Waiting for job details ...</p>
        </div>
        <div class="spinner">
            <ix-spinner size="large"></ix-spinner>
        </div>
    </ng-template>

</div>