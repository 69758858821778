<div class="ix-key-value-list">
    <ix-key-value-list>
        <ix-key-value 
            label="Name"
            labelPosition="left">
            <input 
                class="ag-theme-alpine-dark ag-theme-ix"
                value="{{header?.name}}"
                type="text" 
                slot="custom-value"
                (change)="nameChange($event)" [disabled]=disableInputField/>
        </ix-key-value>
    
        <ix-key-value
            label="Version"
            labelPosition="left">
            <input 
                class="ag-theme-alpine-dark ag-theme-ix"
                value="{{version}}"
                type="text" 
                slot="custom-value"
                (change)="versionChange($event)" [disabled]=disableInputField/>
        </ix-key-value>

    </ix-key-value-list>        
</div>

