import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit{

  constructor(private authService: OidcSecurityService,private router: Router) {}


  ngOnInit(): void {
    // Automatically process the callback when the component is initialized
    
    this.authService.checkAuth().subscribe({
      next: (isAuthenticated) => {
        if (isAuthenticated) {
          console.log('Authentication successful');
          // Navigate to a protected route
          //this.router.navigate(['/fleet-list']);
        } else {
          console.error('Authentication failed');
          // Navigate to an error page if authentication failed
          this.router.navigate(['/error']);
        }
      },
      error: (err) => {
        console.error('Error during authentication check:', err);
        // Navigate to error page
        this.router.navigate(['/error']);
      }
    });
  }

}
