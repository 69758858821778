import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CellValueChangedEvent, ColDef, DomLayoutType } from 'ag-grid-community';
import { ResponseActionDatabase, ResponseActionDatabaseItem } from 'src/app/utils/response-action-database';
import { GenericHeaderEditorComponent } from '../generic-header-editor/generic-header-editor.component';

@Component({
  selector: 'app-response-action-database-editor',
  templateUrl: './response-action-database-editor.component.html',
  styleUrls: ['./response-action-database-editor.component.css']
})
export class ResponseActionDatabaseEditorComponent implements OnInit {

  @Input() crmtFile?: ResponseActionDatabase
  @ViewChild(GenericHeaderEditorComponent) headerEditor?: GenericHeaderEditorComponent
  @Input() disableAppGenericHeaderEditorFields:boolean = true 

  public rowData!: ResponseActionDatabaseItem[]

  private edited = false
  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 10;	

  public columnDefs: ColDef[] = [
    { headerName: "Response Code", field: 'responseCode', width: 200, resizable: false },
    { headerName: "Registration Action Code", field: 'registrationActionCode', width: 250, resizable: false },
    { headerName: "Deregistration Action Code", field: 'deregistrationActionCode', width: 250, resizable: false },
    { headerName: "Interrogation Action Code", field: 'interrogationActionCode', width: 250, resizable: false }
  ];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    
    this.crmtFile = changes["crmtFile"].currentValue

    if(this.crmtFile) {
    
      this.rowData = this.crmtFile.entries
    }
  }

  public onCellValueChanged(e: CellValueChangedEvent<any, any>) {
    this.edited = true
  }

  public getData() {

    const headerEdited = this.headerEditor?.edited

    return {
      done: this.edited || headerEdited,
      fileType: 'service-number-database',
      fileData: this.crmtFile
    }
  }
}
