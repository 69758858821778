import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridApi, GridReadyEvent, RowSelectedEvent } from 'ag-grid-community';
import { DetailsLinkIIComponent } from 'src/app/cell-renderers/details-link/details-link-ii.component';
import * as moment from 'moment';
import { IconCellRendererComponent } from 'src/app/cell-renderers/icon-cell-renderer/icon-cell-renderer.component';

export interface FleetItem {
  name: string;

  // Inventory
  engineNumber: string;
  mtAppver: string;
  mtIMEI: string;
  mtModel: string;
  mtType: string;
  serialNumber: string;
  stockType: string;
  ver: string;

  // Software versions
  currentVersion: string;
  alternateVersion: string;
  currentLanguageVersion: string;

  // Attribute
  connected: string;
  timeConnected: string;
  timeDisconnected: string;

  // Token
  nextToken: string;

  //HMIActivation
  hmiActivated: any;
}


@Component({
  selector: 'app-fleet-list',
  templateUrl: './fleet-list.component.html',
  styleUrls: ['./fleet-list.component.css']
})
export class FleetListComponent implements OnInit {

  @Output() selectedDevices = new EventEmitter<string[]>();
  @Input() hideOption = false
  

  private gridApi!: GridApi;
  private currentPage = 1;
  // Data that gets displayed in the grid
  public rowData$: FleetItem[] = [];
  public isLoading = false;
  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  public pagination = true;
  public paginationPageSize = 15;
  public paginationAutoPageSize = true;
  public nextToken: string = "";
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true
  };
  public columnDefs: ColDef[] =[
    { field: 'engineNumber', headerName: 'Vehicle Number', checkboxSelection: this.hideOption, pinned:'left' },
    { field: 'connected',headerName:'State',cellRenderer:IconCellRendererComponent },
    { field: 'stockType',headerName: 'Vehicle Type' },
    { headerName: 'Configuration', cellRenderer: DetailsLinkIIComponent, hide: this.hideOption, sortable: false, filter: false },
    { field: 'ver',  headerName: 'S/W Version' },
    { field: 'currentVersion',  headerName: 'Current S/W Build' },
    { field: 'alternateVersion',  headerName: 'Available S/W Build' },
    { field: 'serialNumber' },
    { field: 'mtType',  headerName: 'Trx Type' },
    { field: 'mtModel',  headerName: 'Trx Model' },
    { field: 'mtAppver', headerName: 'Trx S/W Version' },
    { field: 'mtIMEI',  headerName: 'Trx IMEI' },
    { field: 'currentLanguageVersion' },
    { field: 'timeConnected' },
    { field: 'timeDisconnected' }
  ];

  constructor(
    private _apiService: ApiService) { }

  ngOnInit(): void {}

  onGridReady(params: GridReadyEvent) {

    this.gridApi= params.api;
    this.updateLoadingOverlay()
    this.getFleetListDevices()
  }
  onPaginationChanged(event: any) {
    const api = event.api; 
    const newPage = api.paginationGetCurrentPage() + 1; 
    if (newPage > this.currentPage && this.nextToken){
      // Fetch data when user goes to new page
      this.currentPage = newPage; 
      this.getFleetListDevices();
    }else if(newPage < this.currentPage && event.newData){
      // Reset the page 
      this.currentPage = newPage; 
    }
    
  }
  getFleetListDevices(){
    this.isLoading= true;
    this.gridApi.showLoadingOverlay();
    this._apiService.fleetListDevices(this.nextToken, this.paginationPageSize).subscribe((data: any) => {
    
    const response = data.map((row: any) => {
      this.nextToken = row.nextToken
      return this.getDetailsForRow(row);            
    })
    const combinedArray = [...this.rowData$, ...response];
    this.rowData$ = combinedArray
    this.isLoading= false;
    this.gridApi.hideOverlay();
    })
  }

  private getDetailsForRow(row: any){
    const inventory = row.shadow?.inventory;
    const softwareVersions = row.shadow?.softwareVersions;
    const attributes = row.attributes;
    const nxtToken = row.nextToken;

    let stockType: string;
    switch (inventory?.stockType) {
      case 0: stockType = "Engine"; break;
      case 1: stockType = "Coach"; break;
      default: stockType = "-"; break;
    }

    const newItem: FleetItem = {
      name: row.thingName,
      
      // Inventory
      engineNumber: inventory?.engineNumber.trim() || "-",
      mtAppver: inventory?.mtAppver || "-",
      mtIMEI: inventory?.mtIMEI || "-",
      mtModel: inventory?.mtModel || "-",
      mtType: inventory?.mtType || "-",
      serialNumber: inventory?.serialNumber || "-",
      stockType: stockType || "-",
      ver: inventory?.ver || "-",

      // Software versions
      currentVersion: softwareVersions?.currentVersion || "-",
      alternateVersion: softwareVersions?.alternateVersion || "-",
      currentLanguageVersion: softwareVersions?.currentLanguageVersion || "-",

      // Attribute
      connected: attributes?.connected ? attributes?.connected.charAt(0).toUpperCase() + attributes?.connected.slice(1) : "False",
      timeConnected: attributes?.timeConnected ? moment.unix(attributes?.timeConnected/1000).local().format('YYYY-MM-DD HH:mm:ss') : "-",
      timeDisconnected: attributes?.timeDisconnected ? moment.unix(attributes?.timeDisconnected/1000).local().format('YYYY-MM-DD HH:mm:ss') : "-",

      // Token
      nextToken: nxtToken,

      //HMIActivation
      hmiActivated:row.hmiActivated
    }
    return newItem
  }
  
  private updateLoadingOverlay(): void {
    if (this.gridApi) {
      if (this.isLoading) {
        this.gridApi.showLoadingOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
    }
  }
  onRowSelected(e: RowSelectedEvent): void {

    const selectedRows = this.agGrid.api.getSelectedRows()

    const selectedDevices = selectedRows.map(item => {
      return item;
    })

    this.selectedDevices.emit(selectedDevices)
  }
}