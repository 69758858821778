<ix-content-header
    variant="Secondary"
    headerTitle="File Type"
    headerSubtitle="Select the desired file type">
</ix-content-header>

<ix-select (itemSelectionChange)="selectionChanged($event)" mode="single">
    <ix-select-item 
        *ngFor="let type of deployableFileTypes"
        [label]="type" 
        [value]="type"
        (itemClick)="onItemClick($event)">
    </ix-select-item>
</ix-select>
