import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalService } from '@siemens/ix-angular';
import { EditStateService } from 'src/app/services/edit-state.service';
import CrmtExitModalComponent from '../components/crmt-exit-modal/crmt-exit-modal.component';

export interface CanComponentDeactivate {
  canDeactivate?: () => Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;
}

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard  {
  constructor(
    private readonly modalService: ModalService,
    private readonly editStateService: EditStateService,
    private readonly router: Router
  ) {}

  public canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: any,
    currentState: any,
    nextState?: any
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.editStateService.getEditedState()) {
      return true
    }else{
      return this.openModal()
    }
  }

  private async openModal(): Promise<boolean | UrlTree> {
    const instance = await this.modalService.open({
      content: CrmtExitModalComponent,
    });

    return new Promise<boolean | UrlTree>((resolve) => {
      instance.onClose.on(() => {
        this.editStateService.setEditedState(false);
          resolve(true);
      });

      instance.onDismiss.on(() => {
        resolve(false);
      });

      instance.htmlElement.addEventListener('keydown',(keyboardEvent: KeyboardEvent) => {});
    });
  }
}
