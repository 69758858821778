<div class="wrapper">
    <ag-grid-angular
        class="ag-theme-alpine-dark ag-theme-ix"
        style="height: 100%; width: 100%;"
        [columnDefs]="columnDefs"
        [rowData]="fileList"
        [rowSelection]=rowSelection
        [animateRows]="true"
        (rowSelected)="onRowSelected($event)">
    </ag-grid-angular>
</div>