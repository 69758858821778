<div class="wrapper">
    <div *ngIf="isLoading" class="spinner">
        <div class="spinner">
          <ix-spinner size="large"></ix-spinner>
        </div>
    </div>
    <div class="model">
        <canvas id="canvas-box" 
            (mousemove)="onMouseMove($event)"
            (mouseleave)="onMouseLeave($event)">
        </canvas>
    </div>
    <div class="controls">
        <ix-icon-button
            class="m-1"
            icon="arrow-left"
            oval
            variant="primary"
            (click)="changeOrientation(false)">
        </ix-icon-button>
        {{orientationIndex + 1}}
        <ix-icon-button
            class="m-1"
            icon="arrow-right"
            oval
            variant="primary"
            (click)="changeOrientation(true)">
        </ix-icon-button>
    </div>
    <ix-row>
        <div class="text-note"><i>
                <ix-row> <b>Note :</b></ix-row>
                <ix-row>Select the correct orientation of the Cab Radio on-board the vehicle from the perspective of looking from
                behind the Cab Radio toward the driver’s control desk.</ix-row>
                <ix-row>In the case of a single cab radio operating in two cabs, select the orientation when facing Cab 1 or
                    Cab A.</ix-row>
                <ix-row>Insert the measured antenna offset to the nearest metre. This is the horizontal offset of the
                    position of the antenna from the front of the train.</ix-row>
            </i>
        </div>
    </ix-row>
    <ix-input-group>
        <input 
            #antennaOffset
            placeholder="Antenna offset" 
            type="text" 
            class="form-control"
            (input)="inputChangeHandler()"/>

        <input 
            #trainClass
            placeholder="Train class" 
            type="text" 
            class="form-control"
            (input)="inputChangeHandler()"/>

    </ix-input-group>
</div>