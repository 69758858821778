import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CellValueChangedEvent, ColDef, DomLayoutType, GridApi, GridOptions, GridReadyEvent, RowEditingStartedEvent } from 'ag-grid-community';
import { TrainCrewParameters, TrainCrewParametersItem } from 'src/app/utils/train-crew-parameters';
import { PhoneNumberEditor } from '../../cell-editors/phone-number-editor.component';
import { GenericHeaderEditorComponent } from '../generic-header-editor/generic-header-editor.component';
import { DropdownRendererComponent } from '../../cell-editors/dropdown-editor.component';
import { EditStateService } from 'src/app/services/edit-state.service';

@Component({
  selector: 'app-train-crew-parameters-editor',
  templateUrl: './train-crew-parameters-editor.component.html',
  styleUrls: ['./train-crew-parameters-editor.component.css']
})
export class TrainCrewParametersEditorComponent implements OnInit {

  @Input() crmtFile?: TrainCrewParameters
  @ViewChild(GenericHeaderEditorComponent) headerEditor?: GenericHeaderEditorComponent

  public rowData!: TrainCrewParametersItem[]

  private edited = false
  private gridApi!: GridApi;
  public disableEditing = true
  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 10;
  public gridOptions: GridOptions = {
    stopEditingWhenCellsLoseFocus: true,
  };
  public columnDefs: ColDef[] = [
    { 
      headerName: "Crew Member", 
      field: 'crewMember', 
      resizable: false
    },
    { 
      headerName: "Function Code", 
      field: 'functionCode', 
      width: 150,
      resizable: true, 
      sortable: true, 
      filter: true,
      editable: true,
      cellEditor: PhoneNumberEditor, 
      cellEditorParams: {
        phoneNumberType: 'digits-only',
        minLength: 2,
        maxLength: 2
      }
    },
    { 
      headerName: "Present", 
      field: 'presentStr', 
      width: 150,
      resizable: false,
      editable: true,
      cellEditor: DropdownRendererComponent,
      cellEditorParams: {
        values: ['Yes', 'No'],
        cellRenderer: DropdownRendererComponent,
      }
    },
    { 
      headerName: "Registration Required", 
      field: 'registrationRequiredFlagStr', 
      width: 200,
      resizable: true, 
      sortable: true, 
      filter: true,
      editable: true,
      cellEditor: DropdownRendererComponent,
      cellEditorParams: {
        values: ['Yes', 'No'],
        cellRenderer: DropdownRendererComponent,
      }
    }
  ];

  constructor(private editStateService: EditStateService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    
    this.crmtFile = changes["crmtFile"].currentValue

    if(this.crmtFile) {

      this.rowData = this.crmtFile.entries
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  public editRow() {
    const selection = this.gridApi.getSelectedNodes()
    const index  = selection[0].rowIndex
    this.gridApi!.setFocusedCell(index as number, "registrationRequiredFlagStr");
    this.gridApi!.startEditingCell({
      rowIndex: index as number,
      colKey: "registrationRequiredFlagStr",
    });
  }

  onRowSelected(event: RowEditingStartedEvent) {
    const selectedRows = this.gridApi.getSelectedRows()

    this.disableEditing = selectedRows.length == 0
  }

  public onCellValueChanged(e: CellValueChangedEvent<any, any>) {
    this.edited = true
    this.editStateService.setEditedState(true);
  }

  public getData() {

    const headerEdited = this.headerEditor?.edited

    return {
      done: this.edited || headerEdited,
      fileType: 'train-crew-parameters',
      fileData: this.crmtFile
    }
  }

  public exitEditMode() {
    this.edited = false
  }
}

