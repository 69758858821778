import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalService, ToastService } from '@siemens/ix-angular';
import * as moment from 'moment';
import { NextBtnStateService } from 'src/app/services/next-btn-state.service';

@Component({
  selector: 'app-crmt-date-selector',
  templateUrl: './crmt-date-selector.component.html',
  styleUrls: ['./crmt-date-selector.component.css']
})
export class CrmtDateSelectorComponent implements OnInit {

  public showDatePicker = false;
  public deployTime: string;
  public deployNow = true;
  public minDate = moment(new Date()).format('YYYY/MM/DD')
  public selectedDate = moment(new Date()).format('YYYY/MM/DD')
  @ViewChild('customModalForErrorMessage', { read: TemplateRef })
  customModalForErrorMessage!: TemplateRef<any>;

  private deployDate: string;

  constructor(private toastService: ToastService, private nextbtnStateService: NextBtnStateService,
    private readonly modalService: ModalService
  ) {
    const now = new Date()

    const year = now.getFullYear()
    const month = (now.getMonth() + 1).toString().padStart(2, '0')
    const day = now.getDate().toString().padStart(2, '0')
    this.deployDate = `${year}/${month}/${day}`

    now.setMinutes(now.getMinutes() + 60)
    const hours = now.getHours().toString().padStart(2, '0')
    const mins = now.getMinutes().toString().padStart(2, '0')
    this.deployTime = `${hours}:${mins}`
  }


  ngOnInit(): void { 
  }
  
  public getData() {

    return {
      done: true,
      now: this.deployNow,
      date: this.deployDate,
      time: this.deployTime,
      startTime: `${this.deployDate}T${this.deployTime}`.replaceAll('/', '-')
    }
  }

  public onCollapsedChange(e: any){
    this.showDatePicker = !e.detail
  }

  public onChange(e: any) {
    if (e.target.id == "deployNow") {
      this.deployNow = true;
      this.nextbtnStateService.setDisableNextBtn(false)
      this.showDatePicker = false;
    }
    else {
      this.deployNow = false;
      this.nextbtnStateService.setDisableNextBtn(true)
      this.showDatePicker = true;
    }
  }

  public dateSelect(e: any) {
    this.deployDate = e.detail.from || this.deployDate
    this.toastService.setPosition('top-right')
    if (!this.validateTime(e)) {
      this.openModalForErrorMessage()
    } else {
      this.selectedDate = e.detail.from
      this.deployTime = e.detail.time
      this.showDatePicker = false
    }
    this.nextbtnStateService.setDisableNextBtn(false)
  }

  public validateTime(e: any) {
    const now = new Date()
    const year = now.getFullYear()
    const month = (now.getMonth() + 1).toString().padStart(2, '0')
    const day = now.getDate().toString().padStart(2, '0')
    const currentDate = `${year}/${month}/${day}`
    now.setMinutes(now.getMinutes() + 30)
    const hours = now.getHours()
    const mins = now.getMinutes()

    const newDeployDate = e.detail.from
    const newTimeArray = e.detail.time.split(':')
    const newTimeHr = Number(newTimeArray[0])
    const newTimeMn = Number(newTimeArray[1])

    if(newDeployDate > currentDate){
      return true
    } else if(newTimeHr > hours) {
      return true
    } else if (newTimeHr === hours && newTimeMn > mins) {
      return true
    } else{
      return false
    }
  }

  async openModalForErrorMessage() {
    const instance = await this.modalService.open({
      content: this.customModalForErrorMessage
    })
    instance.htmlElement.addEventListener('keydown',(keyboardEvent: KeyboardEvent) => {});
  }
} 
