import { CrmtDecoder } from "./crmt-decoder";
import { CrmtEncoder } from "./crmt-encoder";
import { CrmtFile, CrmtHeader, ICrmtFile } from "./crmt-file";
import { environment } from './../../environments/environment';

export interface AttachedSystemsParametersItem {

    system : string;
    attachedFlag: number;
    registrationRequiredFlag: number;
    functionCode: string;
    descriptionReference: number;
    attachedFlagStr: string;
    registrationRequiredFlagStr: string;
}

export class AttachedSystemsParameters extends CrmtFile implements ICrmtFile {

    public entries: AttachedSystemsParametersItem[] = [];
    public static systems = environment.configs.attachedSystemsParametersSystemValues;

    constructor(public header: CrmtHeader) {
        super()
    }

    public static fromRawData(buffer: ArrayBuffer): AttachedSystemsParameters {
        
        const decoder = new CrmtDecoder(buffer);
        // // Extract the common header values
        const header = decoder.getHeader()

        // Create the new object
        const thingy = new AttachedSystemsParameters(header);

        // Extract the actual entries
        const entryCount = decoder.getUint32();

        for(let e = 0; e < entryCount; e++) {
            const system = e < AttachedSystemsParameters.systems.length ? AttachedSystemsParameters.systems[e] : "";
            const attachedFlag = decoder.getUint16(); 
            const registrationRequiredFlag = decoder.getUint16(); 
            const functionCode = decoder.getFixedLengthAscii(2);
            const descriptionReference = decoder.getUint32();
        
            thingy.addEntry(
                system,
                attachedFlag,
                registrationRequiredFlag,
                functionCode,
                descriptionReference
            );
        }

        return thingy;
    }

    public toRawData(): ArrayBuffer {

        const encoder = new CrmtEncoder(10 * 1024)

        // Create the header
        encoder.setHeader(this.header)

        // Write the actual entries
        const entryCount = this.entries.length
        encoder.setUint32(entryCount);

        const entries: AttachedSystemsParametersItem[] = []

        for(let e = 0; e < entryCount; e++) {

            const entry = this.entries[e]
            let registrationRequiredFlag = entry.registrationRequiredFlagStr === 'Yes' ? 1 : 0 
            let attachedFlag = entry.attachedFlagStr  === 'Yes' ? 1 : 0   
            encoder.setUint16(attachedFlag)
            encoder.setUint16(registrationRequiredFlag)
            encoder.setFixedLengthAscii(2, entry.functionCode)
            encoder.setUint32(entry.descriptionReference)
        }

        return encoder.getFileData()
    }

    public addEntry(
        system : string,
        attachedFlag: number,
        registrationRequiredFlag: number,
        functionCode: string,
        descriptionReference: number): void {
        let attachedFlagStr = attachedFlag === 0 ? 'No': 'Yes';    
        let registrationRequiredFlagStr = registrationRequiredFlag == 0 ? 'No':'Yes';
        const entry: AttachedSystemsParametersItem = {
            system,
            attachedFlag,
            registrationRequiredFlag,
            functionCode,
            descriptionReference,
            attachedFlagStr,
            registrationRequiredFlagStr
        }    

        this.entries.push(entry)
    }
}

