import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';


@NgModule({
    imports: [AuthModule.forRoot({
        config: {
              authority: 'https://cognito-idp.eu-west-2.amazonaws.com/eu-west-2_Km7nANcwQ',
              redirectUrl: 'https://crmt-original.64836216846.com/login/callback/',
              postLogoutRedirectUri: window.location.origin,
              clientId: 'abnmpnmlr9fchklijieajelpg',
              scope: 'email openid phone', // 'openid profile offline_access ' + your scopes
              responseType: 'code',
              silentRenew: true,
              useRefreshToken: true,
              renewTimeBeforeTokenExpiresInSeconds: 30,
          }
      })],
    exports: [AuthModule],
})
export class AuthConfigModule {}
