import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ColDef, DomLayoutType } from 'ag-grid-community';
import { FanUsageLog, FanUsageLogItem } from 'src/app/utils/fan-usage-log';

@Component({
  selector: 'app-fan-usage-log-viewer',
  templateUrl: './fan-usage-log-viewer.component.html',
  styleUrls: ['./fan-usage-log-viewer.component.css']
})
export class FanUsageLogViewerComponent implements OnInit {

  @Input() crmtFile?: FanUsageLog

  public rowData!: FanUsageLogItem[]

  private edited = false
  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 10;

  public columnDefs: ColDef[] = [
    { headerName: "Date", field: 'loggedDate', width: 150, resizable: false },
    { headerName: "Time", field: 'loggedTime', width: 150, editable: false, resizable: true, sortable: true, filter: true },
    { headerName: "Event", field: 'fanEvent', resizable: true, sortable: true, filter: true },
    { headerName: "Duration", field: 'duration', resizable: true, sortable: true, filter: true }
  ];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    
    this.crmtFile = changes["crmtFile"].currentValue

    if(this.crmtFile) {

      this.rowData = this.crmtFile.entries
    }
  }

  public getData() {

    return {
      done: this.edited,
      fileType: 'fan-usage-log',
      fileData: this.crmtFile
    }
  }
}
