<fa-icon *ngIf='showIcon' (click)='delete()' [icon]='icon'></fa-icon>
<ng-template #customModal let-modal>
    <div class="box-modal">
        <ix-modal-header class="mod-header">Confirmation</ix-modal-header>
        <ix-modal-content>
            Do you really want to delete?
        </ix-modal-content>
        <ix-modal-footer>
            <ix-button outline class="dismiss-modal" (click)="modal.dismiss('dismiss')">
                Cancel
            </ix-button>
            <ix-button class="close-modal confirm-btn" (click)="modal.close('okay')">
                Delete
            </ix-button>
        </ix-modal-footer>
    </div>
</ng-template>