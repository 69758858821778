import { ToastService } from '@siemens/ix-angular';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, DomLayoutType } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { NextBtnStateService } from 'src/app/services/next-btn-state.service';

export interface FileListItem {
  Key: string;
  Size: number;
}

type LocalConfig =  {

  type: string
  fileCount: number;
  fileTypes: string[];
}

@Component({
  selector: 'app-cloud-file-selector',
  templateUrl: './cloud-file-selector.component.html',
  styleUrls: ['./cloud-file-selector.component.css']
})
export class CloudFileSelectorComponent implements OnInit {

  public selectedType: string = ""
  public selectedFiles?: File[] = []
  public selectedFileText = ""
  public acceptFiles = ""
  public fileConfig: LocalConfig = this.getConfig("")
  public selectedDeviceType?: string
  public enableEditOption: boolean = false
  public isLoading: boolean = false

  // Data that gets displayed in the grid
  public rowData$: FileListItem[] = []
  private jobSubscription?: Subscription
  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 10;

  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;


  @Output() selectedJob = new EventEmitter<string>();
  @Output() deviceTypeSelected = new EventEmitter<string>();
  @Output() fileType = new EventEmitter<string>();

  
  public columnDefs: ColDef[] = [
    { headerName: "Filename", field: 'Key', resizable: true, checkboxSelection: true, width:300 },
    { headerName: "Size", field: 'Size', resizable: true }
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
  };

  public fileTypeSelected = false;

  constructor(
    private readonly _apiService: ApiService,
    private readonly toastService: ToastService,
    private readonly nextBtnStateService: NextBtnStateService
  ) {}

  ngOnInit(): void {
    this.nextBtnStateService.setDisableNextBtn(true)
  }

  ngOnDestroy() {
    this.jobSubscription?.unsubscribe();
  }    

  // Output the job ID
  onCellClicked( e: CellClickedEvent): void {

    const selectedJob = e.data.jobId
    this.selectedJob.emit(selectedJob)
  }

  public fileTypeChanged(event: any): void {
    this.isLoading = true
    this.selectedType = event;
    this.fileType.emit(event);
    this.fileTypeSelected= true;
    this.selectedFiles = []
    this.selectedFileText = ""
    this.fileConfig = this.getConfig(this.selectedType)
    this.acceptFiles = this.fileConfig.fileTypes.join(", ")

    this.updateFileList()
  }

  public deviceTypeChanged(event: any): void {
    this.enableEditOption = true
    this.isLoading = true
    this.selectedDeviceType = event
    this.deviceTypeSelected.emit(event)
    this.updateFileList()
  }

  private updateFileList() {
    if(this.selectedType && this.selectedDeviceType) {
      this._apiService.getFileList(
        this.selectedType, 
        this.selectedDeviceType).subscribe((data: FileListItem[]) => {
          this.isLoading = false
          this.rowData$ = data
      })
    }
  }

  public getData() {

    let files: string | undefined
    const selectedRows = this.agGrid.api.getSelectedRows()

    if(selectedRows.length > 0) {

      files = selectedRows[0].Key
    }
    const done: boolean = 
      Boolean(files) && 
      Boolean(this.selectedType) &&
      Boolean(this.selectedDeviceType)

    return {
      done,
      type: this.selectedType,
      hardware: this.selectedDeviceType,
      files: files
    }
  }

  public onRowSelection(e:any){
    if(e.data.Key && this.selectedType && this.selectedDeviceType){
      this.nextBtnStateService.setDisableNextBtn(false)
    }else{
      this.nextBtnStateService.setDisableNextBtn(true)
    }
  }

  private getConfig(type: string) {

    let config: LocalConfig = {
      type,
      fileCount: 1,
      fileTypes: [""]
    }

    switch(type) {

      case "Standard phonebook":
        config.fileTypes  = [".spb", ".db"]; 
        break;
      
      case "Extended phonebook": 
        config.fileTypes = [".epb", ".db"]; 
        break;

      case "SMS database": 
        config.fileTypes = [".sms", ".db"]; 
        break;

      case "UUS database": 
        config.fileTypes = [".uus", ".db"];
        break;

      case "Software package": 
        config.fileCount = 2;
        config.fileTypes = [".ini", ".tar.gz"]; 
        break;
    }

    return config;
  } 
}
