import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface FaultCodeItem {
  faultCode: string;
  faultType: string;
  faultDescription: string;
}

export interface FaultClassItem {
  faultClassCode: string;
  faultClassStatus: string;
}


@Injectable({
  providedIn: 'root'
})
export class FieldMappingService {

  // The url for the REST API
  private apiUrl = environment.backendConfig.url

  constructor(private http: HttpClient) { }

  public FaultCode(): Observable<FaultCodeItem[]> {
    const url = this.apiUrl + `field-mapping/fault-code`
    const dataObs =  this.http.get<any>(url);

    return dataObs.pipe(
      switchMap((response: any) => of(response.message)),
      catchError(err => { throw new Error(`Download failed from DynamoDB for FaultCode : ${err}`) })
    )
  }

  public FaultClass(): Observable<FaultClassItem[]> {
    const url = this.apiUrl + `field-mapping/fault-class`
    const dataObs =  this.http.get<any>(url);

    return dataObs.pipe(
      switchMap((response: any) => of(response.message)),
      catchError(err => { throw new Error(`Download failed from DynamoDB for FaultClass : ${err}`) })
    )
  }
}
