import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-device-type-selector',
  templateUrl: './device-type-selector.component.html',
  styleUrls: ['./device-type-selector.component.css']
})
export class DeviceTypeSelectorComponent implements OnInit {

  @Output() deviceType = new EventEmitter<string>();
  @Input() fileTypeSelected = false;
  public deviceTypes: string[] = []

  constructor(private apiService: ApiService) { 

    this.apiService.listDeviceTypes().subscribe({
      next: (deviceTypes) => {

        this.deviceTypes = deviceTypes

      },
      error: (e: any) => {

        console.log(e)
      }
    })
  }

  ngOnInit(): void {}

  public selectionChanged(event: any): void {

    this.deviceType.emit(event.detail[0])
  }
}
