export type CrmtHeader = {

    name: string;
    majorVersion: number;
    minorVersion: number;
}

export abstract class CrmtFile {
    constructor() {}
}

export interface ICrmtFile {

    header: CrmtHeader;
    entries: any[]
    toRawData: () => ArrayBuffer;
}
