import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ModalService, ToastService } from '@siemens/ix-angular';

type CancelJobBody = {
  jobId: string,
  comment?: string,
}

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.css']
})
export class JobListComponent implements OnInit {


  public jobId: string = ''
  public refreshed : boolean = false;
  public cancelJobId: string[] = [];
  public errorMessage: string = "";
  @ViewChild('customModalForErrorMessage', { read: TemplateRef })
  customModalForErrorMessage!: TemplateRef<any>;

  constructor(
    private _apiService: ApiService,
    private readonly toastService: ToastService,
    private readonly modalService: ModalService
    ) { }

  disableStandard = false

  ngOnInit(): void {
  }

  public selectedJob(jobId: string) {

    this.jobId = jobId
  }

  public selectedJobForCancellation(cancelJobId: string[]) {
    this.cancelJobId = cancelJobId
  }

  public cancelJob() {
    console.debug("Cancel staring for  : " + this.cancelJobId)

    const cancelJobArray: CancelJobBody[] = this.cancelJobId.map((jobId) => ({
      jobId: jobId.toString(),
      comment: "Cancelling Job from CRMT UI",
    }));

    console.debug("Cancel Job Array", cancelJobArray.toString);

    this._apiService.cancelJobService(cancelJobArray).subscribe({
      next: (response) => {
        this.cancelJobId = [];
        this.showToastMessage();
        console.debug('Response:', response);
      },
      error: (error) => {
        this.cancelJobId = [];
        console.error('Error occurred:', error);
        this.errorMessage = error.error.message || ""
        this.openModalForErrorMessage()
      }
    });
    
  }

  async showToastMessage() {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: 'Cancellation Successful',
      type: "success",
      autoCloseDelay: 10000
    });
  }

  refreshJobList() {
    this.refreshed = true;
    this.jobId = '';
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: 'Refresh Job List button will be available after 30 seconds.',
      type: 'info',
      autoCloseDelay: 10000
    });
  }

  onRefreshCompleted() {
    setTimeout(() => {
      this.refreshed = false;
    }, 30000);
  }

  async openModalForErrorMessage() {
    const instance = await this.modalService.open({
      content: this.customModalForErrorMessage
    })
    instance.htmlElement.addEventListener('keydown',(keyboardEvent: KeyboardEvent) => {});
  }

}
