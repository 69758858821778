import { CrmtDecoder } from "./crmt-decoder";
import { CrmtEncoder } from "./crmt-encoder";
import { CrmtFile, CrmtHeader, ICrmtFile } from "./crmt-file";

export interface ServiceNumberDatabaseItem {
    dialingNumber: string;
    serviceName: string;
}

export class ServiceNumberDatabase extends CrmtFile implements ICrmtFile {

    public entries: ServiceNumberDatabaseItem[] = [];

    constructor(public header: CrmtHeader) {
        super()
    }

    public static fromRawData(buffer: ArrayBuffer): ServiceNumberDatabase {
        
        const decoder = new CrmtDecoder(buffer);

        // // Extract the common header values
        const header = decoder.getHeader()

        // Create the new object
        const thingy = new ServiceNumberDatabase(header);

        // Extract the actual entries
        const entryCount = decoder.getUint32();

        for(let e = 0; e < entryCount; e++) {

            const dialingNumber = decoder.getLengthEncodedAscii();
            const serviceName = decoder.getLengthEncodedAscii();

            thingy.addEntry(
                dialingNumber,
                serviceName);
        }

        return thingy;
    }

    public toRawData(): ArrayBuffer {

        const encoder = new CrmtEncoder(10 * 1024)

        // Create the header
        encoder.setHeader(this.header)

        // Write the actual entries
        const entryCount = this.entries.length
        encoder.setUint32(entryCount);

        const entries: ServiceNumberDatabaseItem[] = []

        for(let e = 0; e < entryCount; e++) {

            const entry = this.entries[e]
            encoder.setLengthEncodedAscii(entry.dialingNumber);
            encoder.setLengthEncodedAscii(entry.serviceName);
        }

        return encoder.getFileData()
    }

    public addEntry(
        dialingNumber: string,
        serviceName: string): void {

        const entry: ServiceNumberDatabaseItem = {
            dialingNumber,
            serviceName
        }    

        this.entries.push(entry)
    }
}

