import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService, ToastService } from '@siemens/ix-angular';
import { switchMap } from 'rxjs';
import { CrmtCommandSelectorComponent } from 'src/app/components/crmt-command-selector/crmt-command-selector.component';
import { CrmtFileEditorComponent } from 'src/app/components/crmt-file-editor/crmt-file-editor.component';
import { OrientationToolComponent } from 'src/app/components/orientation-tool/orientation-tool.component';
import { ApiService, CommandJobRequest, DeployJobRequest } from 'src/app/services/api.service';
import { EditStateService } from 'src/app/services/edit-state.service';
import { FileHeaderService } from 'src/app/services/file-header.service';
import { OrientationPackageData, OrientationToolService } from 'src/app/services/orientation-tool.service';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';
import { Router } from '@angular/router';
import { DuplicateValueCheckServiceService } from 'src/app/services/duplicate-value-check-service.service';

@Component({
  selector: 'app-device-info',
  templateUrl: './device-info.component.html',
  styleUrls: ['./device-info.component.css']
})
export class DeviceInfoComponent implements OnInit {

  @ViewChild(CrmtCommandSelectorComponent) commandSelector?: CrmtCommandSelectorComponent
  @ViewChild(CrmtFileEditorComponent) fileEditor?: CrmtFileEditorComponent
  @ViewChild(OrientationToolComponent) orientatioinTool?: OrientationToolComponent
  @ViewChild('customModal', { read: TemplateRef })
  customModalRef!: TemplateRef<any>;
  public errorMessage: string = "";
  public message: string = "";
  @ViewChild('customModalForErrorMessage', { read: TemplateRef })
  customModalForErrorMessage!: TemplateRef<any>;
  @ViewChild('customModalModalForDeviceNotBelongsToOrg', { read: TemplateRef })
  customModalModalForDeviceNotBelongsToOrg!: TemplateRef<any>;
  
  public selectedTab = 0;
  public showUpdateDevice = false
  public deviceId: string | null = null
  public deviceStockNumber: string | null = null
  public disableStandard = true
  private orientation?: OrientationPackageData
  public isDeviceConnected: boolean = false
  public jobId!: string
  public loadingUploadFile = false
  public fileTypeChanged = false
  public disableUpdate = true
  public disableUpdateDeviceButton = true
  public selectedCommand = ''
  public selectedCommandChanged = false
  public iconColor:string = '';

  constructor(
    private readonly permissions: PermissionsService,
    private readonly _apiService: ApiService,
    private readonly orientationService: OrientationToolService,
    private readonly toastService: ToastService,
    private readonly route: ActivatedRoute,
    private readonly editStateService: EditStateService,
    private readonly modalService: ModalService,
    private readonly elementRef: ElementRef,
    private readonly fileHeaderService: FileHeaderService,
    private duplicateValueCheckService: DuplicateValueCheckServiceService,
    private router: Router
  ) {
    this.deviceId = this.route.snapshot.paramMap.get('deviceId')
    this.deviceStockNumber = this.route.snapshot.paramMap.get('thingStockNumber')?.trim() || "-"

  }

  ngOnInit() {
    this.permissions.getUserLevel().subscribe(level => {

      this.disableStandard = level < UserLevel.STANDARD
    })
  }

  handleInventoryInfo(selection: any) {
    if(JSON.stringify(selection) === "{}"){// case when some user hits the direct device details url and it does not belongs to that org
      const uri = this.route.snapshot.url[0].path
      this.message = `You do not have permission to access radio ${this.deviceStockNumber}. Returning to the ${uri}.`
      this.openModalForDeviceNotBelongsToOrg()
    }else{
      if(selection.attributes?.connected === 'false'){
        this.iconColor='color-alarm--active'
        this.errorMessage = `Radio ${this.deviceStockNumber} is unavailable: Config Files, Command and Orientation features disabled.`
        this.openModalForErrorMessage()
      }else{
        if(selection.hmiActivated==='Unknown' || selection.hmiActivated==='true'){
          this.iconColor='color-warning--active'
          this.errorMessage = `Radio ${this.deviceStockNumber} is unavailable: Config Files, Command and Orientation features disabled.`
          this.openModalForErrorMessage()
        }
        else if(selection.hmiActivated==='false'){
          this.isDeviceConnected = true; // EPCOL-651 Comments
          this.iconColor= 'color-primary--active'
        }
      }
    }

  }

  public changeTab(tabIndex: number) {
    if(this.editStateService.getEditedState()){
      this.openModal(tabIndex)
    }else{
      this.tabsChanged(tabIndex)
    }
  }

  public tabsChanged(tabIndex: number){
    this.selectedTab = tabIndex;
    if (this.selectedTab == 2 ||
      this.selectedTab == 3 ||
      this.selectedTab == 4) {
      this.showUpdateDevice = true
    }
    else {
      this.showUpdateDevice = false
    }
    this.jobId = ''
    this.loadingUploadFile = false
    this.disableUpdateDeviceButton = true
  }

  public getFileType(e: any) {
    if(e && this.selectedTab === 2 && (e === "fault-log" || e === "fan-usage-log" || e === "service-number-database" || e === "response-action-database" || e === "period-timer-parameters")){
      this.disableUpdateDeviceButton = false
    } else {
      this.disableUpdateDeviceButton = true
    }
    this.disableUpdate = e ? false : true
  }

  public isJobCanceled(e:any){
    this.isDeviceConnected = e
    this.changeTab(0)
  }

  public getCommandType(e: any) {
    if(e && e !== this.selectedCommand) {
      this.selectedCommand = e
      this.selectedCommandChanged = true
    }
    this.disableUpdate = e ? false : true
    
  }

  public orientationHandler(orientation: OrientationPackageData) {
    this.orientation = orientation
    this.disableUpdate = (orientation && orientation.offset && orientation.trainClass) ? false : true
  }

  public updateDeviceHandler() {

    if (!this.deviceId) return

    if (this.selectedTab == 2) {

      this.updateFile()
    }
    else if (this.selectedTab == 3) {

      this.sendCommand()
    }
    else if(this.selectedTab == 4) {

      this.updateOrientation()
    }
  }
  validatefileNameVersion(fileData: any): boolean {
    const file  = this.fileHeaderService.getFileHeaderValues();
    const header = fileData.header;
    const fileName = header.name? header.name :file.name
    const majorVersion = header.majorVersion? header.majorVersion : file.majorVersion
    const versionStr = file.versionStr;
    if (fileName === '' && (Number.isNaN(majorVersion))) {
      this.errorMessage = 'File name and version can not be empty.'
      this.openModalForErrorMessage()
      return false
    } else if (fileName === '') {
      this.errorMessage = 'File name can not be empty.'
      this.openModalForErrorMessage()
      return false
    } else if (Number.isNaN(majorVersion)) {
      if (versionStr === '') {
        this.errorMessage = 'File version can not be empty.'
        this.openModalForErrorMessage()
      } else {
        this.errorMessage = 'File version is not valid. Enter numbers only'
        this.openModalForErrorMessage()
      }
      return false
    } else if(!fileData.entries.length){
      this.errorMessage = 'Empty file can not be uploaded.'
      this.openModalForErrorMessage()
      return false;
    }
     else {
      return true
    }
  }
  validateEntries(data: { entries: any[] }): boolean {
    if (!Array.isArray(data.entries)) return false;
  
    return data.entries.every(entry =>
      ["number", "description", "destinationNumber", "textMessage", "messageText"].every(
        key => entry[key] !== null && entry[key] !== ""
      )
    );
  }
  private updateFile() {
    if (!this.deviceId) return
    this.fileTypeChanged = false
    // Fetch the data from file editor component
    const data: any = this.fileEditor?.getData()
    const fileData =  data.fileData;
    //Validate file data 
    let validateFile = true;
    if(data.fileType== 'standard-phonebook' || data.fileType == 'extended-phonebook'
      || data.fileType == 'sms-database'){
      validateFile = this.validateFileData(fileData);
    }
    else if(data.fileType=='uus-database'){
      validateFile = this.validateEntries(fileData);
      if(!validateFile){
        this.errorMessage="Invalid Data Entry: Destination Number and Message Text in the row are mandatory. Please make the correction to proceed."
        this.openModalForErrorMessage();
      }
    }
    const validfileNameVersion = this.validatefileNameVersion(fileData);
    // Send the file to the device
    if (data.done == true && validfileNameVersion && validateFile) {
      this.loadingUploadFile = true
      this.showToastMessage("Sending update request ...")

      this._apiService.putFile(
        `test/encoded.spb`, // TODO - this needs to be put in a tmp-style folder using a uuid filename
        data?.fileType,
        [this.deviceId],
        data.fileData)
        .subscribe({
          next: (data) => {
            this.loadingUploadFile = false
            this.jobId = data.message.jobId
            this.editStateService.setEditedState(false);
            this.showToastMessage()
            this.fileEditor?.exitEditMode()
          },
          error: (e: any) => {
            this.errorMessage = e.message
            this.openModalForErrorMessage()
            this.loadingUploadFile = false
            this.jobId = ''
          }
        })
    } else if(!data.done && validateFile){
      this.toastService.setPosition('top-right');
      this.toastService.show({
        message: 'There are no updates in file hence device will not be updated.',
        type: "info",
        autoCloseDelay: 10000
      });
    }
  }

  private sendCommand() {

    if (!this.deviceId) return

    this.selectedCommandChanged = false
 
    // Fetch the command data
    const data = this.commandSelector?.getData()
    
    if (data.done == true) {

      this.showToastMessage("Sending update request ...");

      const command = data.type.toLowerCase().replace(' ', '-')

      // Prepare the request
      const job: CommandJobRequest = {
        command,
        groups: [],
        devices: [this.deviceId],
        scheduleLater: false,
        swVersion: parseInt(data.swVersion),
        stockType: data.stockType,
        stockNumber: data.stockNumber,
        languageCode: parseInt(data.languageCode)
      }

      // Send it to the device
      this._apiService.sendCommand(job).subscribe({
        next: (data) => {
          this.jobId = data.message.jobId
          this.showToastMessage()
        },
        error: (e) => {
          this.errorMessage = e.message
          this.openModalForErrorMessage()
        }
      })
    }
  }
  validateFileData(file:any): boolean{
    const uniqueData = this.duplicateValueCheckService.getUniqueData();
    if(!uniqueData && !this.validateEntries(file)){
      this.errorMessage="Invalid Data Entry: Duplicate Index Numbers are not permitted. All fields in the row are mandatory. Please make the correction to proceed."
      this.openModalForErrorMessage();
      return false;
    }
    else if(!uniqueData){
        this.errorMessage = "Invalid Data Entry: Duplicate Index Numbers are not permitted. Please make the correction to proceed."
        this.openModalForErrorMessage();
        return false;
      }
      else if(!this.validateEntries(file)){
        this.errorMessage = "Invalid Data Entry: All fields in the row are mandatory. Please make the correction to proceed."
        this.openModalForErrorMessage();
        return false;
      }
      return true
  }

  private updateOrientation() {

    if(!this.deviceId || !this.orientation) return;
    if(!this.orientation.offset || !this.orientation.trainClass) {
      this.errorMessage="All fields are mandatory. Please make the correction to proceed."
      this.openModalForErrorMessage();
      return;
    }
    this.showToastMessage("Sending update request ...")

    const deviceId = this.deviceId // TypeScript complains without this

    this.orientationService.createPackage(this.orientation).pipe(
      switchMap(data => {

        const job: DeployJobRequest = {
          fileType: "software-package",
          devices: [deviceId],
          scheduleLater: false,
          filename: data.message.packageFolder
        }

        return this._apiService.deploy(job)
      })
    )
      .subscribe({
        next: (data) => {
          this.showToastMessage()
        },
        error: (e) => {
          console.error('Error occurred:', e);
          if(e.status == 0){// This is the 0 = unknown Error
            this.errorMessage = "Unable to process your request. Please try again later or contact the administrator."
            this.openModalForErrorMessage()
          }else{
            this.errorMessage = `Error occurred while deploying job. Please contact administrator.`
            this.openModalForErrorMessage()
          }
        }
      })
  }

  public onFileTypeChange(e: any){
    this.fileTypeChanged = e;
    if(e) {
      this.disableUpdate = true
      this.jobId = ''
      this.loadingUploadFile = false
    }
  }

  async showToastMessage(message = "Job submitted") {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message,
      type: "success",
      autoCloseDelay: 10000
    });
  }

  async openModal(tabIndex:number) {
    const instance = await this.modalService.open({
      content: this.customModalRef,
      data: '',
    })

    instance.onClose.on(() => {
      this.editStateService.setEditedState(false);
      this.tabsChanged(tabIndex)
    });

    instance.onDismiss.on(() => {
      const tabs = this.elementRef.nativeElement.querySelector('ix-tabs')
      tabs.selected = this.selectedTab
    })

    instance.htmlElement.addEventListener('keydown',(keyboardEvent: KeyboardEvent) => {});
  }

  async openModalForErrorMessage() {
    const instance = await this.modalService.open({
      content: this.customModalForErrorMessage
    })
    instance.htmlElement.addEventListener('keydown',(keyboardEvent: KeyboardEvent) => {});
  }

  async openModalForDeviceNotBelongsToOrg() {
    const instance = await this.modalService.open({
      content: this.customModalModalForDeviceNotBelongsToOrg,
      data: '',
    })

    instance.onClose.on(() => {
      const uri = this.route.snapshot.url[0].path
      this.router.navigate([`/${uri}`])
    });

    instance.onDismiss.on(() => {
      const uri = this.route.snapshot.url[0].path
      this.router.navigate([`/${uri}`])
    })

    instance.htmlElement.addEventListener('keydown',(keyboardEvent: KeyboardEvent) => {});
  }

  ngOnDestroy() {
    this.editStateService.setEditedState(false);
  }
}
