<div class="wrapper" [hidden]="disablePrivilegedForSubgroup">
    <ix-tabs [selected]="selectedTab">
        <ix-tab-item (click)="changeTab(0)" [hidden]="disablePrivileged">Organisations</ix-tab-item>
        <ix-tab-item (click)="changeTab(1)">Subgroups</ix-tab-item>
    </ix-tabs>
    <div *ngIf="selectedTab === 0" [hidden]="disablePrivileged">
        <ix-content-header variant="Secondary" headerSubtitle="Create a new organisational unit">
        </ix-content-header>

        <div style="padding-bottom: 2em;">
            <ix-input-group style="padding-bottom: 4px;">
                <input #organizationNameInput placeholder="Organisation name" class="form-control" type="text"
                    [(ngModel)]="organizationNameInputValue" (ngModelChange)="changeInputValue()" />
                    <span slot="input-end"><ix-icon name="mandatory" size="16" style = "color: red;"></ix-icon></span>
            </ix-input-group>
            <div *ngIf="oraganizationNameError" style="color: red; width: 37%;">Organization name can only contain letters, numbers, hyphens, colons, or underscores.It can't contain any spaces.</div>
        </div>
        
        <ix-input-group>
            <input #organizationDescriptionInput placeholder="Description       " class="form-control" type="text"
                [(ngModel)]="organizationDescriptionInputValue" />
        </ix-input-group>

        <ix-button [disabled]="disablePrivilegedOrg || oraganizationNameError" class="m-1" variant="primary" (click)="createOrganization()">
            Create Organisation
        </ix-button>

    </div>
    <div *ngIf="selectedTab === 1">

        <ix-content-header variant="Secondary"
            headerSubtitle="Create a subgroup within an existing organisational unit">
        </ix-content-header>

        <ix-select i18nPlaceholder="Parent group" (valueChange)="selectionChanged($event)" mode="single" [value]="value">
            <ix-select-item
              *ngFor="let name of groupNames"
              [label]="name.indexOf(':') > 0 ? name.substring(name.indexOf(':') + 1) : name"
              [value]="name">
            </ix-select-item>
        </ix-select>

        <div style="padding-bottom: 2em;">
            <ix-input-group style="padding-bottom: 4px;">
                <input #subgroupNameInput placeholder="Subgroup name" class="form-control" type="text" 
                [(ngModel)]="subgroupNameInputValue" (ngModelChange)="validateSubgroupName()"/>
            </ix-input-group>
            <div *ngIf="subgroupNameError" style="color: red; width: 37%;">Subgroup name can only contain letters, numbers, hyphens, colons, or underscores.It can't contain any spaces.</div>
        </div>

        <ix-button [disabled]="disablePrivilegedForSubgroup || disableSubgroup" class="m-1" variant="primary" (click)="createGroup()">
            Create Subgroup
        </ix-button>
    </div>
</div>