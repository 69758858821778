
export class CrcAlgorithm {

    // Compute the CRC of the file data
    // This uses the magic >>> operator for unsigned arithmetic
    public static computeCrc(buffer: ArrayBuffer): number {

        let crc = 0xFFFFFFFF;

        // Skip the first 4 bytes as that's where the CRC is stored
        const bufferSlice = buffer.slice(4)
        const bytes = new Uint8Array(bufferSlice)

        // Process each byte
        bytes.forEach(byte => {

            // Process each bit
            for(let bit = 0; bit < 8; bit++) {
                const bitValue = ((byte ^ crc) & 1) >>> 0
                crc >>>= 1

                if(bitValue) {
                    crc = (crc ^ 0xEDB88320) >>> 0
                }

                byte >>>= 1
            }
        })

        return ~crc >>> 0
    }
}