import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { fetchAuthSession } from "aws-amplify/auth";
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class SuperUserGuard {

  constructor(
    private _apiService: ApiService,
    private router: Router)
  {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    const guardResult = this._apiService.getAccountInfo().pipe(
      switchMap(async accountInfo => {
        const { tokens } = await fetchAuthSession();
        // TODO remove
        console.log("user belongs to following groups: " + tokens?.accessToken.payload["cognito:groups"])

        const groups = tokens?.accessToken.payload["cognito:groups"];
        if (Array.isArray(groups)) {
          const superUser = groups.includes(`${accountInfo.appPrefix}-super`);
          const url = this.router.createUrlTree(["/", "profile"]);
          return of(superUser || url)
        }
        return of(false) // TODO this seems slightly wrong, check the Okta code and try to replicate behaviour
      })
    )

    return guardResult
  }
}
