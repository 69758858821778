import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ToastService } from '@siemens/ix-angular';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, DomLayoutType, GridReadyEvent, IGetRowsParams } from 'ag-grid-community';
import { ApiService } from 'src/app/services/api.service';

export interface JobStatusItem {
  engineNumber: string;
  status: string
}
const ELEMENT_DATA: JobStatusItem[] = []
@Component({
  selector: 'app-crmt-job-status',
  templateUrl: './crmt-job-status.component.html',
  styleUrls: ['./crmt-job-status.component.css']
})
export class CrmtJobStatusComponent implements OnInit {

  @Input() jobId!: string;
  dataSource = ELEMENT_DATA;
  public status: string = ''
  public isLoading: boolean = false;
  public isDisabled: boolean= false;
  public details: any

  public columnDefs: ColDef[] = [];

  // Data that gets displayed in the grid
  public rowData$: any[] = [];

  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 15;
  public nextToken: string = "";
  public defaultColDef: ColDef = {
    sortable: false,
    filter: true,
    resizable: true,
  };

  private thingsArray: string[] = []
  constructor(
    private readonly  _apiService: ApiService,
    private readonly toastService : ToastService) { }

  ngOnInit(): void {
    this.columnDefs = [
      { field: 'engineNumber', headerName: 'Stock Number' },
      { field: 'status', headerName: 'Device Job Status', minWidth: 550 }
    ]
  }

  onGridReady(params: GridReadyEvent) {

    this.agGrid.api = params.api;
    
    const dataSource = {
      getRows: async (rowsParams: IGetRowsParams) => {
        const startRow = rowsParams.startRow;
        const endRow = rowsParams.endRow;
        this.agGrid.api.showLoadingOverlay();
        const devicesSlice = this.getSlice(this.thingsArray, startRow,endRow);
        this._apiService.getJobStatusDetails(this.jobId, devicesSlice).subscribe(response => {
          const indDeviceStatus = response.message.jobstatus;

          const updatedMap = Object.keys(indDeviceStatus).map(key =>{
          indDeviceStatus[key]= indDeviceStatus[key].replace('CANCELED', 'CANCELLED');
              if(indDeviceStatus[key]==="Loading Job Status"){
                indDeviceStatus[key]=this.status;
              }
          });
          const objectMap = new Map<string, string>(Object.entries(indDeviceStatus));
          const resultArray = Array.from(objectMap).map(([key, value]) => {

            return {
              engineNumber : key?.trim() || "-",
              status: value
            }; 
          });
          
          this.rowData$ = resultArray;
          const lastRow = this.thingsArray.length;

          this.agGrid.api.hideOverlay();
          rowsParams.successCallback(resultArray, lastRow)
        })
      }
    };
    
    params.api.setDatasource(dataSource);
  }
  
  ngOnChanges(changes: SimpleChanges) {
    const jobId = changes["jobId"].currentValue
    if(jobId) {
      this.getJobStatus(jobId)
    }
  }

  refreshJobStatus(){
    this.isDisabled =true;
    this.getJobStatus(this.jobId);
    this.toastService.setPosition('top-right');
    setTimeout(() => {
      this.isDisabled= false;
    }, 30000);
    this.toastService.show({
      message: 'Refresh Job Status button will be available after 30 seconds.',
      type: 'info',
      autoCloseDelay: 10000
    });
  }

  getJobStatus(jobId:string){
      // Fetch and display the job status
      this.isLoading = true;
     
      this.thingsArray.length = 0
      this._apiService.getJobStatus(jobId).subscribe(data => { 
        this.status = data.message.job.status.replace('_', ' ')
        this.status=this.status.replace('CANCELED', 'CANCELLED');
        this.details = data.message.job.jobProcessDetails;
        this.thingsArray = data.message.devices
        
        this.isLoading= false;
      })        
  }
  getSlice(arr: any[], start: number, end: number): any[] {
    return arr.slice(start, end);
  }

}
