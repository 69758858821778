import { CrmtDecoder } from "./crmt-decoder";
import { CrmtEncoder } from "./crmt-encoder";
import { CrmtFile, CrmtHeader, ICrmtFile } from "./crmt-file";

export class PeriodTimerParametersItem {
    public emergencyVoiceCallSetupTime = 0.0
    public periodicSignalStrengthInterval = 0.0
    public periodicDiagnosticTestInterval = 0.0
    public selfTestsTimeout = 0.0
    public hmiOffTimeout = 0.0
    public emergencyConfirmNetworkTimeout = 0.0
    public digitalMonitoringInterval = 0.0
    public tmCallSetupTimeout = 0.0
    public paAutioFeedInterval = 0.0
    public transceiverResponseTimeout = 0.0
    public simCacheDelay = 0.0
    public cfNoReplyTimeout = 0.0
    public dsdInitialDelay = 0.0
    public dsdWarningDuration = 0.0
    public hmiActivityDuration = 0.0
    public deregistrationTimeout = 0.0
    public loudspeakerTestDuration = 0.0
    public handsetTestDuration = 0.0
    public publicAddresstestDuration = 0.0
    public followMeRetryDelay = 0.0
    public powerupTestResultDisplayDuration = 0.0
    public onDemandTestResultDisplayDuration = 0.0
    public hmiIndicationhandTime = 0.0
    public autoAnswerAlertingPeriod = 0.0
    public outgoingFixedTextMsgTimeoutNote1 = 0.0
    public keepRegistrationPromptTimeout = 0.0
    public intercomToControllerAnsTimeout = 0.0
    public networkLossFaultReportingDelay = 0.0
    public eRecSectoridRequestInterval = 0.0
}

export class PeriodTimerParameters extends CrmtFile implements ICrmtFile {

    public entries: PeriodTimerParametersItem[] = [];

    constructor(public header: CrmtHeader) {
        super()
        this.entries[0] = new PeriodTimerParametersItem()
    }

    public static fromRawData(buffer: ArrayBuffer): PeriodTimerParameters {
        
        const decoder = new CrmtDecoder(buffer);

        // // Extract the common header values
        const header = decoder.getHeader()

        // Create the new object
        const thingy = new PeriodTimerParameters(header);

        // Extract the actual entries (and discard)
        const entryCount = decoder.getUint32();

        const emergencyVoiceCallSetupTime = decoder.getFloat64();
        const periodicSignalStrengthInterval = decoder.getFloat64();
        const periodicDiagnosticTestInterval = decoder.getFloat64();
        const selfTestsTimeout = decoder.getFloat64();
        const hmiOffTimeout = decoder.getFloat64();
        const emergencyConfirmNetworkTimeout = decoder.getFloat64();
        const digitalMonitoringInterval = decoder.getFloat64();
        const tmCallSetupTimeout = decoder.getFloat64();
        const paAutioFeedInterval = decoder.getFloat64();
        const transceiverResponseTimeout = decoder.getFloat64();
        const simCacheDelay = decoder.getFloat64();
        const cfNoReplyTimeout = decoder.getFloat64();
        const dsdInitialDelay = decoder.getFloat64();
        const dsdWarningDuration = decoder.getFloat64();
        const hmiActivityDuration = decoder.getFloat64();
        const deregistrationTimeout = decoder.getFloat64();
        const loudspeakerTestDuration = decoder.getFloat64();
        const handsetTestDuration = decoder.getFloat64();
        const publicAddresstestDuration = decoder.getFloat64();
        const followMeRetryDelay = decoder.getFloat64();
        const powerupTestResultDisplayDuration = decoder.getFloat64();
        const onDemandTestResultDisplayDuration = decoder.getFloat64();
        const hmiIndicationhandTime = decoder.getFloat64();
        const autoAnswerAlertingPeriod = decoder.getFloat64();
        const outgoingFixedTextMsgTimeoutNote1 = decoder.getFloat64();
        const keepRegistrationPromptTimeout = decoder.getFloat64();
        const intercomToControllerAnsTimeout = decoder.getFloat64();
        const networkLossFaultReportingDelay = decoder.getFloat64();
        const eRecSectoridRequestInterval = decoder.getFloat64();
        thingy.entries[0] = {
            emergencyVoiceCallSetupTime,
            periodicSignalStrengthInterval,
            periodicDiagnosticTestInterval,
            selfTestsTimeout,
            hmiOffTimeout,
            emergencyConfirmNetworkTimeout,
            digitalMonitoringInterval,
            tmCallSetupTimeout,
            paAutioFeedInterval,
            transceiverResponseTimeout,
            simCacheDelay,
            cfNoReplyTimeout,
            dsdInitialDelay,
            dsdWarningDuration,
            hmiActivityDuration,
            deregistrationTimeout,
            loudspeakerTestDuration,
            handsetTestDuration,
            publicAddresstestDuration,
            followMeRetryDelay,
            powerupTestResultDisplayDuration,
            onDemandTestResultDisplayDuration,
            hmiIndicationhandTime,
            autoAnswerAlertingPeriod,
            outgoingFixedTextMsgTimeoutNote1,
            keepRegistrationPromptTimeout,
            intercomToControllerAnsTimeout,
            networkLossFaultReportingDelay,
            eRecSectoridRequestInterval
        }

        return thingy;
    }

    public toRawData(): ArrayBuffer {

        const encoder = new CrmtEncoder(10 * 1024)

        // Create the header
        encoder.setHeader(this.header)

        // Write the actual entries
        const entryCount = 29
        encoder.setUint32(entryCount);

        const entry = this.entries[0]
        encoder.setFloat64(entry.emergencyVoiceCallSetupTime)
        encoder.setFloat64(entry.periodicSignalStrengthInterval)
        encoder.setFloat64(entry.periodicDiagnosticTestInterval)
        encoder.setFloat64(entry.selfTestsTimeout)
        encoder.setFloat64(entry.hmiOffTimeout)
        encoder.setFloat64(entry.emergencyConfirmNetworkTimeout)
        encoder.setFloat64(entry.digitalMonitoringInterval)
        encoder.setFloat64(entry.tmCallSetupTimeout)
        encoder.setFloat64(entry.paAutioFeedInterval)
        encoder.setFloat64(entry.transceiverResponseTimeout)
        encoder.setFloat64(entry.simCacheDelay)
        encoder.setFloat64(entry.cfNoReplyTimeout)
        encoder.setFloat64(entry.dsdInitialDelay)
        encoder.setFloat64(entry.dsdWarningDuration)
        encoder.setFloat64(entry.hmiActivityDuration)
        encoder.setFloat64(entry.deregistrationTimeout)
        encoder.setFloat64(entry.loudspeakerTestDuration)
        encoder.setFloat64(entry.handsetTestDuration)
        encoder.setFloat64(entry.publicAddresstestDuration)
        encoder.setFloat64(entry.followMeRetryDelay)
        encoder.setFloat64(entry.powerupTestResultDisplayDuration)
        encoder.setFloat64(entry.onDemandTestResultDisplayDuration)
        encoder.setFloat64(entry.hmiIndicationhandTime)
        encoder.setFloat64(entry.autoAnswerAlertingPeriod)
        encoder.setFloat64(entry.outgoingFixedTextMsgTimeoutNote1)
        encoder.setFloat64(entry.keepRegistrationPromptTimeout)
        encoder.setFloat64(entry.intercomToControllerAnsTimeout)
        encoder.setFloat64(entry.networkLossFaultReportingDelay)
        encoder.setFloat64(entry.eRecSectoridRequestInterval)

        return encoder.getFileData()
    }

    public addEntry(item: PeriodTimerParametersItem): void {

        this.entries.push(item)
    }
}

