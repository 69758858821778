import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, DomLayoutType, GetDataPath, GetRowIdFunc, GetRowIdParams, GridApi, GridOptions, GridReadyEvent, RowClassRules, RowNode, RowSelectedEvent } from 'ag-grid-community';
import { DetailsLinkComponent } from 'src/app/cell-renderers/details-link/details-link.component';
import { FileCellRenderer } from '../fleet-tree/fleet-tree.component';
import { ToastService } from '@siemens/ix-angular';
import * as moment from 'moment';
import { IconCellRendererComponent } from 'src/app/cell-renderers/icon-cell-renderer/icon-cell-renderer.component';
@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css']
})
export class GroupListComponent {

  @Input() deviceType= '';
  @Input() fileTypeValue = '';
  @Input() updateCommand = '';
  @Output() selectedGroups = new EventEmitter<{groups: string[],devices:string[]}>();
  
  private groupNameNextTokenMap = new Map<string, string>();
  private filePathNextTokenMap = new Map<string[], string>();
  private currentPage = 1;
  private rowNode: any = undefined; // will be set when group is expanded
  private nextToken: string = "";
  private id = 0;

  public rowData: any[] | null = [];
  public disablePrivileged= true;
  private gridApi!: GridApi;
  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 10;	
  public rowSelection: "single" | "multiple" = "multiple";
  private selectedParentNodeIndex: any;
  public isAnyCheckboxChecked: boolean = false;
  public isGroupEmpty: boolean= false;
  public isToasterShown: boolean = false;
  public isLoading = false;
  public defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
    resizable: true,
  };

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  public groupDefaultExpanded = 0;
  private currentlyExpandedGroup: RowNode | null = null;

  constructor(
    private _apiService: ApiService,
    private toastService: ToastService,
  ) { }

  public columnDefs: ColDef[] = [
    { field: 'description',flex:1, minWidth: 200  },
    { field: 'connected',headerName:'State',cellRenderer:IconCellRendererComponent , flex:1 ,minWidth: 130 },
    { field: 'stockType',headerName: 'Vehicle Type', flex:1 ,minWidth: 150 },
    { field: 'configuration', cellRenderer: DetailsLinkComponent, flex:1 ,minWidth: 145 },
    { field: 'deviceType', headerName: 'Device Type', flex:1 ,minWidth: 130  },
    { field: 'ver', headerName: 'S/W Version', flex:1 ,minWidth: 130  },
    { field: 'currentVersion', headerName: 'Current S/W Build', flex:1 ,minWidth: 180 },
    { field: 'alternateVersion', headerName: 'Available S/W Build', flex:1 ,minWidth: 220  },
    { field: 'serialNumber', flex:1 ,minWidth: 150 },
    { field: 'mtType', headerName: 'Trx Type', flex:1 ,minWidth: 150},
    { field: 'mtModel', headerName: 'Trx Model', flex:1 ,minWidth: 150 },
    { field: 'mtAppver', headerName: 'Trx S/W Version', flex:1 ,minWidth: 180},
    { field: 'mtIMEI', headerName: 'Trx IMEI', flex:1 ,minWidth: 180},
    { field: 'currentLanguageVersion', flex:1 ,minWidth: 225 },
    { field: 'timeConnected', flex:1 ,minWidth: 180 },
    { field: 'timeDisconnected',flex:1, minWidth: 200}
  ];

  public getDataPath: GetDataPath = (data: any) => data.filePath;
  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => params.data.id;

  public autoGroupColumnDef: ColDef = {
    headerName: 'Device Fleet',
    rowGroup: true,
    minWidth: 300,
    pinned:'left',
    sortable: true, filter: true,
    cellRendererParams: {
      checkbox: true,
      suppressCount: true,
      innerRenderer: FileCellRenderer,
    },
    valueFormatter: this.showValuesWithoutUUID
  };
  public gridOptions: GridOptions = {
    paginateChildRows: true,
    pagination: true,
    paginationPageSize: 15,
    animateRows: false,
  };
//onchanges has been added to load data when device type changes or if send command is selected
  ngOnChanges(changes: SimpleChanges): void {
    if((changes['updateCommand'] && !changes['updateCommand'].firstChange) || 
    (changes['fileTypeValue'] && !changes['fileTypeValue'].firstChange) ){
      this.groupNameNextTokenMap.clear()
      this.rowData=[];
      this.loadData();
    }
  }

  private loadData(): void {
    this.isLoading = true;
    this.updateLoadingOverlay();
    this._apiService.groupDeviceTree().subscribe((data: any) => {
      this.rowData = data.message.map((row:any) => {
        // If row type is 'file', get detailed row data
        if (row.type === 'file') {
          
          return this.getDetailsForRow(row);
        }
        this.filePathNextTokenMap.set(row.filePath, "")
        row.id = this.newId();
        return row;
      });
      this.isLoading = false;
      this.updateLoadingOverlay();
    });
  }
  
  onRowGroupOpened(event: any) {
    const rowNode = event.node; // Get the row node that was expanded
    this.handleRowGroupOpened(event)
    // Invoke api if its not previously opened group
    if (event.expanded && !this.groupNameNextTokenMap.has(rowNode.key)) {
      this.rowNode = rowNode
      const filePathToRemove = rowNode.childrenAfterFilter.find((child:any) => child.data.type === 'file');     
      this.getChildData(this.rowNode, filePathToRemove);
    }
  } 

  onPaginationChanged(event: any) {
    const api = event.api; 
    const newPage = api.paginationGetCurrentPage() + 1; 
    if (newPage >= this.currentPage && this.rowNode != undefined  ) {
        if(api.paginationIsLastPageFound() && event.newPage){
          let parentNode = this.rowNode.parent.key != null ? this.rowNode.parent : this.rowNode
          if(this.groupNameNextTokenMap.has(parentNode.key) 
            && (this.groupNameNextTokenMap.get(parentNode.key) != undefined)){
              this.rowNode = parentNode
              this.currentPage = newPage; 
              this.getChildData(this.rowNode, null)
          }
        }
     }else if(newPage < this.currentPage 
          && this.rowNode != undefined  
          && event.newPage) {
          this.currentPage = newPage;  
     }
  }

  private getChildData(rowNode: any, filePathToRemove: any){
    this.gridApi.showLoadingOverlay();
    if(this.filePathNextTokenMap.has(rowNode.data.filePath)){
      this.nextToken = this.filePathNextTokenMap.get(rowNode.data.filePath)!
    }
    
    this._apiService.fleetDeviceTreeInGroup(this.nextToken, 
      this.gridOptions.paginationPageSize!, rowNode.key, 
      rowNode.data.filePath).subscribe((data: any) => {
      
      const response = data.message.map((row: any) => {
          //To show details of a thing
          return this.getDetailsForRow(row);            
      })

      const removeDummyRow = (dummyRowPath: string[]): void => {
        const updatedData = this.rowData!.filter(row => {
          return row.filePath.length !== dummyRowPath.length || 
              !row.filePath.every((tag: string) => dummyRowPath.includes(tag)) || // Check if all file path are in dummyRowPath
              !dummyRowPath.every(tag => row.filePath.includes(tag)); // Check if all dummyRowPath are in file path
      });
        // Since updatedData returns new array of filtered object 
        // hence clear row data and assigned filtered data to it
        this.rowData!.length = 0
        this.rowData?.push(...updatedData)
      };
      if(filePathToRemove && filePathToRemove.data){
        removeDummyRow(filePathToRemove.data.filePath)
      }
      
      const combinedArray = [...this.rowData!, ...response];
      this.rowData = this.checkDuplicateVehicleNumber(combinedArray)
      const token = data.message.length > 0 ? data.message[0].nextToken : undefined
      this.filePathNextTokenMap.set(rowNode.data.filePath, token)
      this.groupNameNextTokenMap.set(rowNode.key,token)
      this.gridApi.hideOverlay();
    })
  }

  private checkDuplicateVehicleNumber(response: any[]): any[] {
    const countMap: Map<string, number> = new Map();

    // First pass: count occurrences of each key
    response.forEach(item => {
      if (item.type === 'file') {
        const key = JSON.stringify(item.filePath);
        countMap.set(key, (countMap.get(key) || 0) + 1);
      }
    });

    // Second pass: append serial number to duplicates
    response.forEach(item => {
      const filePath = item.filePath
      const key = JSON.stringify(filePath);
      if (countMap.get(key)! > 1 && key.indexOf('(') == -1) {
        item.filePath[filePath.length - 1] = `${item.filePath[filePath.length - 1]} (${item.serialNumber})`;
      }
    });

    return response;
  }

  private newId() {
    return this.id++
  }


  private getDetailsForRow(row: any,){
    const inventory = row.shadow?.inventory;
    const softwareVersions = row.shadow?.softwareVersions;
    const attributes = row.attributes;
    let stockType: string;
    switch (inventory?.stockType) {
      case 0: stockType = "Engine"; break;
      case 1: stockType = "Coach"; break;
      default: stockType = "-"; break;
    }
     
    return {
      id: this.newId(),
      deviceID: row.deviceID,
      deviceName: row.deviceName,
      deviceType: row.deviceType,
      filePath: row.filePath,
      type: row.type,
      mtAppver: inventory?.mtAppver || "-" ,
      mtIMEI: inventory?.mtIMEI || "-",
      mtModel: inventory?.mtModel || "-",
      mtType: inventory?.mtType || "-",
      serialNumber: inventory?.serialNumber || "-",
      stockType: stockType ||  "-",
      ver: inventory?.ver || "-",

      // Software versions
      currentVersion: softwareVersions?.currentVersion || "-",
      alternateVersion: softwareVersions?.alternateVersion || "-",
      currentLanguageVersion: softwareVersions?.currentLanguageVersion || "-",

      // Attribute
      connected: attributes?.connected ? attributes?.connected.charAt(0).toUpperCase() + attributes?.connected.slice(1) : "False",
      timeConnected: attributes?.timeConnected ? moment.unix(attributes?.timeConnected/1000).local().format('YYYY-MM-DD HH:mm:ss') : "-" ,
      timeDisconnected: attributes?.timeDisconnected ? moment.unix(attributes?.timeDisconnected/1000).local().format('YYYY-MM-DD HH:mm:ss') : "-",
      hmiActivated: row.hmiActivated
    }
}

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.updateLoadingOverlay();
  }

  private updateLoadingOverlay(): void {
    if (this.gridApi) {
      if (this.isLoading) {
        this.gridApi.showLoadingOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
    }
  }
 
  onRowSelected(e: RowSelectedEvent): void {
    this.handleParentNodeDeselection(e);
    this.isAnyCheckboxChecked = e.api.getSelectedNodes().length > 0;
    const { selectedGroups, selectedDevices } = this.categorizeSelectedRows(e);
    this.selectedGroups.emit({ groups: selectedGroups, devices: selectedDevices });
    this.gridApi.redrawRows();
}

private handleParentNodeDeselection(e: RowSelectedEvent): void {
  const { api } = e;
  const selectedNodes = api.getSelectedNodes();
  
  const parent = selectedNodes
    .map(node => {
      let currentNode:any = node;
      while (currentNode && currentNode.level !== 0) {
        currentNode = currentNode.parent;
      }
      return currentNode;
    })
    .find(node => node !== null);
  
  if (parent?.parent?.childrenAfterGroup) {
    parent.parent.childrenAfterGroup.forEach((child: { setSelected: (arg0: boolean) => void; }) => {
      this.selectedParentNodeIndex = parent.rowIndex;
      if (child !== parent) {
        child.setSelected(false);
      }
    });
  }
  
  this.selectedParentNodeIndex = parent?.rowIndex;
}

private categorizeSelectedRows(e: RowSelectedEvent): { selectedGroups: string[], selectedDevices: any[] } {
  const selectedNodes = e.api.getSelectedNodes();
  const selectedGroups: string[] = [];
  let selectedDevices: any[] = [];

  selectedNodes.forEach(node=>{
    
    const lastPathSegment = node.data.filePath[node.data.filePath.length - 1];
    if(node.data.type==='folder'){
      if(!node.allChildrenCount){
        if(!this.isGroupEmpty){
          this.toastService.setPosition('top-right');
        this.toastService.show({
          message: `You can not deploy to empty group`,
          type: "error",
          autoCloseDelay: 10000
        }); 
        this.isGroupEmpty=true;
        } 
        node.setSelected(false);
      }
      else if(!node.parent?.isSelected()){
        selectedGroups.push(lastPathSegment);
        this.isGroupEmpty=false;
      }
    }
    else if(!node.parent?.isSelected()){
      if(!selectedGroups.length){
      selectedDevices.push({engineNumber:lastPathSegment,name:node.data.deviceName,deviceType: node.data.deviceType});
    }
     else if(selectedGroups.length){
      if(!this.isToasterShown){
        this.toastService.setPosition('top-right');
        this.toastService.show({
          message: `You cannot select groups and devices together for a single deployment`,
          type: "error",
          autoCloseDelay: 10000
        });
        this.isToasterShown = true;
      }
        node.setSelected(false);
      }
      this.isToasterShown= true;
    }
  })
  this.isToasterShown = false;

  return { selectedGroups, selectedDevices };
}

  public rowClassRules: RowClassRules = {
    'disabled-groups': (params) => {
      return params.node.level==0 && params.node.rowIndex!==this.selectedParentNodeIndex && this.isAnyCheckboxChecked
    }
  };

  showValuesWithoutUUID(params: any) {
    if (params.value) {    
      var groupName = params.value.indexOf(':')
      return groupName > 0 ? params.value.substring(groupName + 1) : params.value;
    }
    return params.value;
  }

  private handleRowGroupOpened(event: any): void {
    const node = event.node as RowNode;
    if (node.expanded && this.currentlyExpandedGroup && this.currentlyExpandedGroup !== node && node.level==0) {
      // Collapse the previously expanded group
      this.currentlyExpandedGroup.setExpanded(false);
    }
    
    if (node.expanded) {
      this.currentlyExpandedGroup = node;
    } else if (this.currentlyExpandedGroup === node) {
      this.currentlyExpandedGroup = null;
    }
  }
}