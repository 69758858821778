// The component makes use of AG Grid's tree-control capability and is
// based on the examples on their website.
// See https://www.ag-grid.com/angular-data-grid/tree-data/
import { Component, TemplateRef, ViewChild } from '@angular/core';
import {
  ColDef,
  GetDataPath,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  RowDragEnterEvent,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { DeleteRowComponent } from 'src/app/cell-renderers/delete-row/delete-row.component';
import { DetailsLinkComponent } from 'src/app/cell-renderers/details-link/details-link.component';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';
import { NewOrganisationData } from '../create-group/create-group.component';
import * as moment from 'moment';
import { ModalService, ToastService } from '@siemens/ix-angular';
import { IconCellRendererComponent } from 'src/app/cell-renderers/icon-cell-renderer/icon-cell-renderer.component';
import { UrlTree } from '@angular/router';


@Component({
  selector: 'app-fleet-tree',
  templateUrl: './fleet-tree.component.html',
  styleUrls: ['./fleet-tree.component.css']
})
export class FleetTreeComponent {

  public disablePrivileged = true
  public groupNameNextTokenMap = new Map<string, string>();
  public filePathNextTokenMap = new Map<string[], string>();
  public filePathArray= new Array()
  private gridApi!: GridApi;
  private id = 0
  private currentPage = 1;
  private rowNode: any = undefined; // will be set when group is expanded
  private rowDragEventData!: RowDragEnterEvent; 
  private selectedGroup: any;
  
  public filePaths: any[] = []
  public confirmDisabled: boolean = true

  @ViewChild('customModal', { read: TemplateRef })
  customModalRef!: TemplateRef<any>;

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  public columnDefs: ColDef[]=[
    { field: 'description',flex:1, minWidth: 200  },
    { field: 'connected',headerName:'State',cellRenderer:IconCellRendererComponent , flex:1 ,minWidth: 130 },
    { field: 'stockType',headerName: 'Vehicle Type', flex:1 ,minWidth: 150 },
    { field: 'configuration', cellRenderer: DetailsLinkComponent, flex:1 ,minWidth: 145, sortable: false, filter: false },
    { field: 'ver', headerName: 'S/W Version', flex:1 ,minWidth: 170  },
    { field: 'currentVersion', headerName: 'Current S/W Build', flex:1 ,minWidth: 180 },
    { field: 'alternateVersion', headerName: 'Available S/W Build', flex:1 ,minWidth: 220  },
    { field: 'serialNumber', flex:1 ,minWidth: 150 },
    { field: 'mtType', headerName: 'Trx Type', flex:1 ,minWidth: 150},
    { field: 'mtModel', headerName: 'Trx Model', flex:1 ,minWidth: 150 },
    { field: 'mtAppver', headerName: 'Trx S/W Version', flex:1 ,minWidth: 180},
    { field: 'mtIMEI', headerName: 'Trx IMEI', flex:1 ,minWidth: 180},
    { field: 'currentLanguageVersion', flex:1 ,minWidth: 225 },
    { field: 'timeConnected', flex:1 ,minWidth: 180 },
    { field: 'timeDisconnected',flex:1, minWidth: 200}
  ];
  public rowData: any[] | null = []
  public groupDefaultExpanded = 0;
  public nextToken: string = "";
  public getDataPath: GetDataPath = (data: any) => {
    return data.filePath;
  };
  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id;
  };
  public autoGroupColumnDef: ColDef = {
    rowDrag: params => params.node.data.type == 'file' && !this.disablePrivileged,
    headerName: 'Device Fleet',
    minWidth:300,
    width:300,
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: FileCellRenderer,
    },
    sortable: true, filter: true,
    //cellClass: 'no-right-border',
    pinned:'left',
  };

  public gridOptions: GridOptions = {
    paginateChildRows: true,
    pagination: true,
    paginationPageSize: 15,
    animateRows: false,
  };
  constructor(
    private readonly permissions: PermissionsService,
    private _apiService: ApiService,
    private readonly modalService: ModalService,
    private readonly toastService: ToastService) {

    }
    
    ngOnInit() { 
      this.customColdefs();
      this._apiService.groupDeviceTree().subscribe((data: any) => {
      this.rowData = data.message.map((row: any) => {
        if (row.type === 'file') {
          return this.getDetailsForRow(row);
        }
        this.filePathNextTokenMap.set(row.filePath, "")
        row.id = this.newId();
        this.filePathArray.push({'filePath':row.filePath,'id':row.id, 'type': 'folder'})
        return row;
      })
    })   
  }

  private customColdefs(){
    this.permissions.getUserLevel().subscribe(level => {
      this.disablePrivileged = level < UserLevel.PRIVILEGED

      if(level >= UserLevel.PRIVILEGED) {
        // All the user to delete rows
        this.columnDefs = [
          ...this.columnDefs,
          { cellRenderer: DeleteRowComponent, pinned:'right', minWidth:50, width:60 },
        ];
      }
    })
  }

  onRowGroupOpened(event: any) {
    const rowNode = event.node; // Get the row node that was expanded
    // Invoke api if its not previously opened group
    if (event.expanded && !this.groupNameNextTokenMap.has(rowNode.key)) {
      this.rowNode = rowNode
      const filePathToRemove = rowNode.childrenAfterFilter.find((child:any) => child.data.type === 'file');          
      this.getChildData(this.rowNode,filePathToRemove);
    }
  } 

  onPaginationChanged(event: any) {
    const api = event.api; 
    const newPage = api.paginationGetCurrentPage() + 1; 
    if (newPage >= this.currentPage && this.rowNode != undefined  ) {
        if(api.paginationIsLastPageFound() && event.newPage){
          let parentNode = this.rowNode.parent.key != null ? this.rowNode.parent : this.rowNode
          if(this.groupNameNextTokenMap.has(parentNode.key) 
            && (this.groupNameNextTokenMap.get(parentNode.key) != undefined)){
              this.rowNode = parentNode
              this.currentPage = newPage; 
              this.getChildData(this.rowNode, null)
          }
        }
     }else if(newPage < this.currentPage 
          && this.rowNode != undefined  
          && event.newPage) {
          this.currentPage = newPage;  
     }
  }

  private getChildData(rowNode: any, filePathToRemove : any){
    this.gridApi.showLoadingOverlay();
    if(this.filePathNextTokenMap.has(rowNode.data.filePath)){
      this.nextToken = this.filePathNextTokenMap.get(rowNode.data.filePath)!
    }
    
    this._apiService.fleetDeviceTreeInGroup(this.nextToken, 
      this.gridOptions.paginationPageSize!, rowNode.key, 
      rowNode.data.filePath).subscribe((data: any) => {
      
      const response = data.message.map((row: any) => {
          //To show details of a thing
          return this.getDetailsForRow(row);            
      })
      
      const removeDummyRow = (dummyRowPath: string[]): void => {
        const updatedData = this.rowData!.filter(row => {
          return row.filePath.length !== dummyRowPath.length || 
              !row.filePath.every((tag: string) => dummyRowPath.includes(tag)) || // Check if all file path are in dummyRowPath
              !dummyRowPath.every(tag => row.filePath.includes(tag)); // Check if all dummyRowPath are in file path
      });
        // Since updatedData returns new array of filtered object 
        // hence clear row data and assigned filtered data to it
        this.rowData!.length = 0
        this.rowData?.push(...updatedData)
      };
      if(filePathToRemove && filePathToRemove.data){
        removeDummyRow(filePathToRemove.data.filePath)
      }
      
      const combinedArray = [...this.rowData!, ...response];
      this.rowData = this.checkDuplicateVehicleNumber(combinedArray)
      const token = data.message.length > 0 ? data.message[0].nextToken : undefined
      this.filePathNextTokenMap.set(rowNode.data.filePath, token)
      this.groupNameNextTokenMap.set(rowNode.key,token)
      this.gridApi.hideOverlay();
    })
  }

  private checkDuplicateVehicleNumber(response: any[]): any[] {
    const countMap: Map<string, number> = new Map();

    // First pass: count occurrences of each key
    response.forEach(item => {
      if (item.type === 'file') {
        const key = JSON.stringify(item.filePath);
        countMap.set(key, (countMap.get(key) || 0) + 1);
      }
    });

    // Second pass: append serial number to duplicates
    response.forEach(item => {
      const filePath = item.filePath
      const key = JSON.stringify(filePath);
      if (countMap.get(key)! > 1 && key.indexOf('(') == -1) {
        item.filePath[filePath.length - 1] = `${item.filePath[filePath.length - 1]} (${item.serialNumber})`;
      }
    });

    return response;
  }

 private getDetailsForRow(row: any){
          const inventory = row.shadow?.inventory;
          const softwareVersions = row.shadow?.softwareVersions;
          const attributes = row.attributes;
          let stockType: string;
          switch (inventory?.stockType) {
            case 0: stockType = "Engine"; break;
            case 1: stockType = "Coach"; break;
            default: stockType = "-"; break;
          }
          return {
            id: this.newId(),
            deviceID:row.deviceID,
            deviceName:row.deviceName,
            filePath: row.filePath,
            type:row.type,
            mtAppver: inventory?.mtAppver || row.mtAppver || "-" ,
            mtIMEI: inventory?.mtIMEI || row.mtIMEI ||"-",
            mtModel: inventory?.mtModel || row.mtModel || "-",
            mtType: inventory?.mtType || row.mtType || "-",
            serialNumber: inventory?.serialNumber || row.serialNumber || "-",
            stockType: row.stockType || stockType ||  "-",
            ver: inventory?.ver || row.ver || "-",

            // Software versions
            currentVersion: softwareVersions?.currentVersion || row.currentVersion || "-",
            alternateVersion: softwareVersions?.alternateVersion || row.alternateVersion || "-",
            currentLanguageVersion: softwareVersions?.currentLanguageVersion || row.currentLanguageVersion || "-",

            // Attribute
            connected: attributes?.connected ? attributes?.connected.charAt(0).toUpperCase() + attributes?.connected.slice(1) : "False",
            timeConnected: attributes?.timeConnected ? moment.unix(attributes?.timeConnected/1000).local().format('YYYY-MM-DD HH:mm:ss') : (row.timeConnected || "-") ,
            timeDisconnected: attributes?.timeDisconnected ? moment.unix(attributes?.timeDisconnected/1000).local().format('YYYY-MM-DD HH:mm:ss') : (row.timeDisconnected || "-"),
            hmiActivated: row.hmiActivated
          }
 }
 onRowDragEnter(event: RowDragEnterEvent) {
    this.filePaths = this.filePathArray
    this.rowDragEventData = event
    this.openModal();
    
  }
  public selection(event: any) {
    if(event != null) {
      this.confirmDisabled = false
      this.selectedGroup = event
    }else{
      this.confirmDisabled = true
    }
    
  }
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    params.api.addEventListener('deleteRowEvent', this.onDeleteRowEvent.bind(this));
    this.gridApi.sizeColumnsToFit();
    if (this.rowData != null) {
      this.gridApi.showLoadingOverlay();
    } else {
      this.gridApi.hideOverlay();
    }
  }

    // Capture the custom event in the parent component
    onDeleteRowEvent(event: any) {
      const params = event.params
      const node = params.node
      const deleteRow = (deleteRowPath: string[]): void => {
        const updatedData = this.rowData!.filter(row => {
          return row.filePath.length !== deleteRowPath.length || 
              !row.filePath.every((tag: string) => deleteRowPath.includes(tag)) || // Check if all file path are in deleteRowPath
              !deleteRowPath.every(tag => row.filePath.includes(tag)); // Check if all deleteRowPath are in file path
      });
        // Since updatedData returns new array of filtered object 
        // hence clear row data and assigned filtered data to it
        this.rowData!.length = 0
        this.rowData?.push(...updatedData)
      };
      if(node && node.data){
        deleteRow(node.data.filePath)
      }

    }

  public addOrganization(newOrganisationData: NewOrganisationData) {

    const newEntry = {
      
      id: this.newId(),
      filePath: [newOrganisationData.newOrganization],
      description: newOrganisationData.newOrganizationDescription,
      type: 'folder'
    }      
    
    this.gridApi.applyTransaction({
      add: [newEntry],
    });
    //This was needed as applytransaction was not working 
    //while adding the group and rowdata was not getting 
    //updated hence explicetly pushed data into rowData array     
    this.rowData?.push(newEntry)
    this.filePathArray.push(newEntry)
  }

  public addSubgroup(event: any) {

    const parentRow = this.gridApi.forEachNode((node: any) => {

      const data = node.data
      const folderName = data.filePath[data.filePath.length - 1]

      if(data.type == 'folder' && folderName == event.parentGroup) {

        const filePathCopy = data.filePath.slice();
        filePathCopy.push(event.newGroupName);
    
        const newEntry = {
          
            id: this.newId(),
            filePath: filePathCopy,
            type: 'folder'
        }      
        
        this.gridApi.applyTransaction({
          add: [newEntry],
        });
        //This was needed as applytransaction was not working 
        //while adding the group and rowdata was not getting 
        //updated hence explicetly pushed data into rowData array 
        this.rowData?.push(newEntry)
        this.filePathArray.push(newEntry)
      }
    })

  }

  private copyToPath(
    newParentPath: string[],
    node: IRowNode,
    allUpdatedNodes: any[]
  ) {
    
    // last part of the file path is the file name
    const oldPath = node.data.filePath;
    const rowData = node.data;
    const thingName= node.data.deviceName;
    const thingDisplayName = oldPath[oldPath.length - 1] 
    const newParent = newParentPath[newParentPath.length - 1];
    
    const newParentPathCopy = newParentPath.slice();
    newParentPathCopy.push(thingDisplayName);

    const newEntry = this.getDetailsForRow({...rowData, filePath: newParentPathCopy})  
    
    allUpdatedNodes.push(newEntry);
    const allNodes = [...this.rowData!, ...allUpdatedNodes]
    this.checkDuplicateVehicleNumber(allNodes)

    this._apiService.fleetAddThingToGroup(newParent, thingName).subscribe((result: any) => {
      console.log("Result", result)
    })  
  }
  
  // this updates the filePath locations in our data, we update the data
  // before we send it to AG Grid
  
  private moveToPath(
    newParentPath: string[],
    node: IRowNode,
    allUpdatedNodes: any[]) {
    // last part of the file path is the file name
    const oldPath = node.data.filePath;
    const oldParent = oldPath[oldPath.length - 2];
    const thingName= node.data.deviceName;
    const thingDisplayName = oldPath[oldPath.length - 1]
    const newParent = newParentPath[newParentPath.length - 1];
    
    const newParentPathCopy = newParentPath.slice();
    newParentPathCopy.push(thingDisplayName);
    node.data.filePath = newParentPathCopy;
    allUpdatedNodes.push(node.data);
    const allNodes = [...this.rowData!, ...allUpdatedNodes]
    this.checkDuplicateVehicleNumber(allNodes)
  
    this._apiService.fleetMoveThingToGroup(oldParent, newParent, thingName).subscribe((result: any) => {
      console.log("Result", result)
    })
  }

  private newId() {
    return this.id++
  }

  private async openModal(): Promise<boolean | UrlTree> {
    const instance = await this.modalService.open({
      size: '480',
      content: this.customModalRef,
      data: ''
    });

    return new Promise<boolean | UrlTree>((resolve) => {
      instance.onClose.on(() => {
        // the data we want to move
        const movingData = this.rowDragEventData.node.data;
        // take new parent path from parent,
        const newParentPath = this.selectedGroup.filePath
          ? this.selectedGroup.filePath
          : [];
        // For checking the equalness we have to see the paths with 
        // only org not with device. So if device path is present then remove it  
        const moveDataFilePath = movingData.filePath && movingData.filePath.length > 1 
        ? movingData.filePath.slice(0, -1) : movingData.filePath
        
        const needToChangeParent = !arePathsEqual(newParentPath, moveDataFilePath);

        if (needToChangeParent) {

          const oldOrg = this.rowDragEventData.node.data.filePath[0]
          const newOrg = newParentPath[0]
        
          if(oldOrg == newOrg) {

            const updatedRows: any[] = [];
            this.moveToPath(newParentPath, this.rowDragEventData.node, updatedRows);
            this.gridApi.applyTransaction({
              update: updatedRows,
            });
            this.gridApi.clearFocusedCell();
          }
          else {
            const updatedRows: any[] = [];
            this.copyToPath(newParentPath, this.rowDragEventData.node, updatedRows);
            this.gridApi.applyTransaction({
              add: updatedRows,
            });
            this.gridApi.clearFocusedCell();        
          }
        }        
          resolve(true);
      });

      instance.onDismiss.on(() => {
        resolve(true);
      });

      instance.htmlElement.addEventListener('keydown',(keyboardEvent: KeyboardEvent) => {});
    });
  }
}

function arePathsEqual(path1: string[], path2: string[]) {
  if (path1.length !== path2.length) {
    return false;
  }
  let equal = true;
  path1.forEach(function (item, index) {
    if (path2[index] !== item) {
      equal = false;
    }
  });
  return equal;
}

export class FileCellRenderer {
  private eGui: any;

  init(params: any) {
    const tempDiv = document.createElement('div');
    const groupName = params.value.indexOf(':')
    const value = groupName > 0 ? params.value.substring(groupName + 1) : params.value;
    const icon = getFileIcon(params.value);
    tempDiv.innerHTML = icon
      ? '<i class="' +
        icon +
        '"/>' +
        '<span class="filename">' +
        value +
        '</span>'
      : value;

    this.eGui = tempDiv.firstChild;
  }
  getGui() {
    return this.eGui;
  }
}

function getFileIcon(filename: string) {
  const icon = filename.endsWith('1') || filename.endsWith('.wav')
    ? 'far fa-file-audio'
    : filename.endsWith('.xls')
    ? 'far fa-file-excel'
    : filename.endsWith('.txt')
    ? 'far fa-file'
    : filename.endsWith('.pdf')
    ? 'far fa-file-pdf'
    : 'far fa-folder';

    return icon
}

