import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditStateService {
  private editedState = new BehaviorSubject<boolean>(false);
  editedState$ = this.editedState.asObservable();

  constructor() { }

  setEditedState(isEdited: boolean) {
    this.editedState.next(isEdited);
  }

  getEditedState(): boolean {
    return this.editedState.getValue();
  }
}
