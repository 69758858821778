import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CellValueChangedEvent, ColDef, DomLayoutType, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { CabRadioParameters } from 'src/app/utils/cab-radio-parameters';
import { RadioParameterEditor } from 'src/app/cell-editors/radio-parameter-editor.component';
import { GenericHeaderEditorComponent } from '../generic-header-editor/generic-header-editor.component';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';
import { EditStateService } from 'src/app/services/edit-state.service';

type LocalItem = {
  parameter: string;
  value: string;
  min?: number;
  max?: number;
}

@Component({
  selector: 'app-cab-radio-parameters-editor',
  templateUrl: './cab-radio-parameters-editor.component.html',
  styleUrls: ['./cab-radio-parameters-editor.component.css']
})
export class CabRadioParametersEditorComponent implements OnInit {

  @Input() crmtFile?: CabRadioParameters
  @ViewChild(GenericHeaderEditorComponent) headerEditor?: GenericHeaderEditorComponent

  public rowData!: LocalItem[]
  public enableEditing = false
  public disableEditing = true

  private edited = false
  private gridApi!: GridApi;
  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 10;
  public gridOptions: GridOptions = {
    stopEditingWhenCellsLoseFocus: true,
  };
    public columnDefs: ColDef[] = [
    { 
      headerName: "Parameter", 
      field: 'parameter', 
      resizable: true 
    },
    { 
      headerName: "Value", 
      field: 'value', 
      resizable: true, 
      sortable: true, 
      filter: true,
      editable: () => this.enableEditing,
      cellEditor: RadioParameterEditor, 
    }
  ];

  constructor(
    private readonly permissions: PermissionsService, private editStateService: EditStateService) {}

  ngOnInit() { 

    this.permissions.getUserLevel().subscribe(level => {

      this.enableEditing = level >= UserLevel.STANDARD
      this.disableEditing = !this.enableEditing
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    
    this.crmtFile = changes["crmtFile"].currentValue

    if(this.crmtFile) {
      this.rowData = this.mapDataToTable(this.crmtFile)
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  public onCellValueChanged(e: CellValueChangedEvent<any, any>) {
    this.edited = true
    this.mapTableToData(this.rowData, this.crmtFile!)
    this.editStateService.setEditedState(true);
  }


  public getData() {

    const headerEdited = this.headerEditor?.edited

    return {
      done: this.edited || headerEdited,

      fileType: 'cab-radio-parameters',
      fileData: this.crmtFile
    }
  }

  public exitEditMode() {
    this.edited = false
  }

  public editRow() {
    const selection = this.gridApi.getSelectedNodes()
    const index  = selection[0].rowIndex
    this.gridApi!.setFocusedCell(index as number, "value");
    this.gridApi!.startEditingCell({
      rowIndex: index as number,
      colKey: "value",
    });
  }

  private mapDataToTable(data: CabRadioParameters): LocalItem[] {

    const e = data.entries[0]

    // The SIM password is a string type so it's easier to treat all the numeric
    // values as strings too.
    const tableData = [
      { 
        parameter: "Handset Volume", 
        value: e.handsetVolume.toString(), 
        min: 1,
        max: 5 
      },
      { 
        parameter: "Speaker Volume", 
        value: e.speakerVolume.toString(), 
        min: 1,
        max: 5
      },
      { 
        parameter: "Cab Noise Rating", 
        value: e.cabNoiseRating.toString(), 
        min: 0,
        max: 2
      },
      { 
        parameter: "SIM PIN",
        value: e.simPin.toString(), 
        min: 0,
        max: 999999
      },
      { 
        parameter: "DSD attached", 
        value: e.dsdAttached.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "2nd Control Panel ", 
        value: e.secondControlPanel.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "HP Cradle", 
        value: e.hpCradle.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "Display AoC", 
        value: e.displayAoC.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "GPS fitted", 
        value: e.gpsFitted.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "UPS fitted", 
        value: e.upsFitted.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "TRX Internal Gain", 
        value: e.trxInternalGain.toString(), 
        min: 0,
        max: 10
      },
      { 
        parameter: "Radio Audio Receive Level", 
        value: e.radioAudioReceiveLevel.toString(), 
        min: 0,
        max: 255
      },
      { 
        parameter: "Radio Audio Transmit Level", 
        value: e.radioAudioTransmitLevel.toString(), 
        min: 0,
        max: 255
      },
      { 
        parameter: "Microphone Audio Level", 
        value: e.microphoneAudioLevel.toString(), 
        min: 0,
        max: 255
      },
      { 
        parameter: "Maximum Acceptable HDOP for GPS", 
        value: e.maximumAcceptableHdop.toString(), 
        min: 0,
        max: 10
      },
      { 
        parameter: "Text Message Date/Time Display", 
        value: e.textMessageDateTimeDisplay.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "PA Audio Level", 
        value: e.paAudioLevel.toString(), 
        min: 0,
        max: 255
      },
      { 
        parameter: "DSD Alarm Sounded ", 
        value: e.dsdAlarmSounded.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "Driver's Key fitted", 
        value: e.driversKeyFitted.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "Phonebook Button Use", 
        value: e.phonebookButtonUse.toString(), 
        min: 0, 
        max: 1
      },
      { 
        parameter: "Allow Forced Deregistration", 
        value: e.allowForcedDeregistration.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "TBR Interface Type", 
        value: e.tbrInterfaceType.toString(), 
        min: 0,
        max: 2
      },
      { 
        parameter: "Bulk Registration Use", 
        value: e.bulkRegistrationUse.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "Bulk Deregistration Use", 
        value: e.bulkDeregistrationUse.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "Shunting Registration Required", 
        value: e.shuntingRegistrationRequired.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "Common Shunting Group Activation", 
        value: e.commonShuntingGroupActivation.toString(), 
        min: 0,
        max: 2
      },
      { 
        parameter: "Deregistration on Entry to Shunting", 
        value: e.deregistrationOnEntry.toString(), 
        min: 0,
        max: 2
      },
      { 
        parameter: "Deregistration on Exit from Shunting", 
        value: e.deregistrationOnExit.toString(), 
        min: 0,
        max: 2
      },
      { 
        parameter: "Allow Train Number Registration", 
        value: e.allowTrainNumberRegistration.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "Allow Shunting Driver Registration", 
        value: e.allowShuntingDriverRegistration.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "Use Shunting Team Number", 
        value: e.useShuntingTeamNumber.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "Filter on Shunting Area ID", 
        value: e.filterOnShuntingAreaId.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "Restore Registrations on Exit from Shunting Mode", 
        value: e.restoreRegistrationsOnExit.toString(), 
        min: 0,
        max: 1
      },
      { 
        parameter: "Train Computer Interface", 
        value: e.trainComputerInterface.toString(), 
        min: 0,
        max: 2
      },
    ]

    return tableData
  }

  private mapTableToData(table: LocalItem[], data: CabRadioParameters) {

    let i = 0

    data.entries[0].handsetVolume = parseInt(table[i++].value)
    data.entries[0].speakerVolume = parseInt(table[i++].value) 
    data.entries[0].cabNoiseRating = parseInt(table[i++].value) 
    data.entries[0].simPin = table[i++].value // This one is actually a string
    data.entries[0].dsdAttached = parseInt(table[i++].value) 
    data.entries[0].secondControlPanel = parseInt(table[i++].value) 
    data.entries[0].hpCradle = parseInt(table[i++].value) 
    data.entries[0].displayAoC = parseInt(table[i++].value) 
    data.entries[0].gpsFitted = parseInt(table[i++].value) 
    data.entries[0].upsFitted = parseInt(table[i++].value) 
    data.entries[0].trxInternalGain = parseInt(table[i++].value) 
    data.entries[0].radioAudioReceiveLevel = parseInt(table[i++].value) 
    data.entries[0].radioAudioTransmitLevel = parseInt(table[i++].value) 
    data.entries[0].microphoneAudioLevel = parseInt(table[i++].value) 
    data.entries[0].maximumAcceptableHdop = parseInt(table[i++].value) 
    data.entries[0].textMessageDateTimeDisplay = parseInt(table[i++].value) 
    data.entries[0].paAudioLevel = parseInt(table[i++].value) 
    data.entries[0].dsdAlarmSounded = parseInt(table[i++].value) 
    data.entries[0].driversKeyFitted = parseInt(table[i++].value) 
    data.entries[0].phonebookButtonUse = parseInt(table[i++].value) 
    data.entries[0].allowForcedDeregistration = parseInt(table[i++].value) 
    data.entries[0].tbrInterfaceType = parseInt(table[i++].value) 
    data.entries[0].bulkRegistrationUse = parseInt(table[i++].value) 
    data.entries[0].bulkDeregistrationUse = parseInt(table[i++].value) 
    data.entries[0].shuntingRegistrationRequired = parseInt(table[i++].value) 
    data.entries[0].commonShuntingGroupActivation = parseInt(table[i++].value) 
    data.entries[0].deregistrationOnEntry = parseInt(table[i++].value) 
    data.entries[0].deregistrationOnExit = parseInt(table[i++].value) 
    data.entries[0].allowTrainNumberRegistration = parseInt(table[i++].value) 
    data.entries[0].allowShuntingDriverRegistration = parseInt(table[i++].value) 
    data.entries[0].useShuntingTeamNumber = parseInt(table[i++].value) 
    data.entries[0].filterOnShuntingAreaId = parseInt(table[i++].value) 
    data.entries[0].restoreRegistrationsOnExit = parseInt(table[i++].value) 
    data.entries[0].trainComputerInterface = parseInt(table[i++].value) 
  }
}
