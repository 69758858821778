import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-icon-cell-renderer',
  template: `
  <ix-icon *ngIf="connected && nodeType" name="circle-filled" class="connect" size="16"></ix-icon>
  <ix-icon *ngIf="amber && nodeType" name="circle-filled" class="amber" size="16"></ix-icon>
  <ix-icon *ngIf="red && nodeType" name="circle-filled" class="disconnect" size="16"></ix-icon>`,
  styles: [
    `.connect{
    color: var(--theme-color-primary--active);
    }
    .amber{
    color: var(--theme-color-warning--active);
    }
    .disconnect{
    color: var(--theme-color-alarm--active); 
  }  
    `
  ]
})
export class IconCellRendererComponent implements OnInit, ICellRendererAngularComp {
  private node: any
  public connected: boolean = false;
  public nodeType: boolean = false;
  public amber:boolean = false;
  public red: boolean= false;
  constructor() { }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.node = params.node;
    if(this.node.data?.hasOwnProperty('connected')){
      this.nodeType = true;
    }
    if(this.node.data?.connected==="True"){
      if(this.node.data.hmiActivated==='Unknown' || this.node.data.hmiActivated==='true'){
        this.amber= true
      }
      else if(this.node.data.hmiActivated==='false'){
        this.connected= true;
      }
    }
    else{
      this.red= true;
    }
  }

  ngOnInit(): void {
  }
  

}
