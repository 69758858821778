import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DuplicateValueCheckServiceService {

  private gridData: any[] = [];

  constructor() { }

  setGridData(data: any[]): void {
    this.gridData = data;
  }

  getUniqueData() : boolean{
    const indexes = this.gridData.map(obj => Object.values(obj)[0]);
    const checkSet = new Set(indexes);
    return checkSet.size ===  this.gridData.length;
  }

}
