<div class="wrapper">
    <ag-grid-angular
      class="ag-theme-alpine-dark ag-theme-ix"
      style="height: 100%; width: 100%;"
      [columnDefs]="columnDefs"
      [rowData]="rowData$"
      [rowSelection]="'multiple'"
      [animateRows]="true"
      [defaultColDef]="defaultColDef"
      (gridReady)="onGridReady($event)"
      [pagination]="pagination"
      [paginationAutoPageSize]="paginationAutoPageSize"
      (rowSelected)="onRowSelected($event)"
      (paginationChanged)="onPaginationChanged($event)"
      >
      
    </ag-grid-angular>
</div>  