import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { ModalService, ToastService } from '@siemens/ix-angular';
import { ApiService } from 'src/app/services/api.service';
import { SmsDatabase } from 'src/app/utils/sms-database';
import { StandardPhonebook } from 'src/app/utils/standard-phonebook';
import { ExtendedPhonebook } from 'src/app/utils/extended-phonebook';
import { UusDatabase } from 'src/app/utils/uus-database';
import { CabRadioParameters } from 'src/app/utils/cab-radio-parameters';
import { AttachedSystemsParameters } from 'src/app/utils/attached-systems-parameters';
import { TrainCrewParameters } from 'src/app/utils/train-crew-parameters';
import { PeriodTimerParameters } from 'src/app/utils/period-timers-parameters';
import { ServiceNumberDatabase } from 'src/app/utils/service-number-database';
import { ResponseActionDatabase } from 'src/app/utils/response-action-database';
import { FaultLog } from 'src/app/utils/fault-log';
import { FanUsageLog } from 'src/app/utils/fan-usage-log';
import { CrmtFile } from 'src/app/utils/crmt-file';
import { GenericFileEditorComponent } from '../generic-file-editor/generic-file-editor.component';
import { FieldMappingService, FaultCodeItem, FaultClassItem } from 'src/app/services/field-mapping.service';
import { EditStateService } from 'src/app/services/edit-state.service';

@Component({
  selector: 'app-crmt-file-editor',
  templateUrl: './crmt-file-editor.component.html',
  styleUrls: ['./crmt-file-editor.component.css']
})
export class CrmtFileEditorComponent implements OnInit {

  @Input() device!: string | null
  @Output() getSelectedFileType = new EventEmitter<string>();
  
  @ViewChild(GenericFileEditorComponent) fileEditor?: GenericFileEditorComponent
  @Output() fileTypeChanged = new EventEmitter<boolean>()
  @Output() jobCanceled = new EventEmitter<any>();
  @ViewChild('customModal', { read: TemplateRef })
  customModalRef!: TemplateRef<any>;
  public errorMessage: string = "";
  @ViewChild('customModalForErrorMessage', { read: TemplateRef })
  customModalForErrorMessage!: TemplateRef<any>;

  public fileTypes = [
    "Cab radio parameters",
    "Attached systems parameters",
    "Train crew parameters",
    "Period timer parameters",
    "Service number database",
    "Response action database",
    "Fault log",
    "Fan usage log"
  ]

  public fileType = ''
  public file: CrmtFile = {}
  public standardPhonebook?: StandardPhonebook
  public extendedPhonebook?: ExtendedPhonebook
  public smsDatabase?: SmsDatabase
  public uusDatabase?: UusDatabase
  public cabRadioParameters?: CabRadioParameters
  public attachedSystemsParameters?: AttachedSystemsParameters
  public trainCrewParameters?: TrainCrewParameters
  public periodTimerParameters?: PeriodTimerParameters
  public serviceNumberDatabase?: ServiceNumberDatabase
  public responseActionDatabase?: ResponseActionDatabase
  public faultLog?: FaultLog
  public fanUsageLog?: FanUsageLog
  public isLoading: boolean = false
  public isError: boolean = false
  public nrMode!: boolean
  public edited: boolean = false
  public value?: string
  private originalSelectedValue?: string

  constructor(
    private _apiService: ApiService,
    private readonly toastService: ToastService,
    private fieldMappingService: FieldMappingService,
    private editStateService: EditStateService,
    private readonly modalService: ModalService,
    private readonly elementRef: ElementRef
  ) { }

  async openModal(event?: any) {
    const instance = await this.modalService.open({
      content: this.customModalRef,
      data: '',
    })

    instance.onClose.on((a) => {//on click of Ok button on popup
      const selectedItem = this.elementRef.nativeElement.querySelector('ix-select')
      this.originalSelectedValue = selectedItem.value;
      this.changeFileType(event)
      this.editStateService.setEditedState(false)
    });

    instance.onDismiss.on((arg) => { // when cancel is clicked on popup
      const selectedItem = this.elementRef.nativeElement.querySelector('ix-select')
      selectedItem.value = this.originalSelectedValue
    })

    instance.htmlElement.addEventListener('keydown',(keyboardEvent: KeyboardEvent) => {});

  }

  ngOnInit(): void {
    this.getSelectedFileType.emit()
    this._apiService.getAccountInfo().subscribe(data => {
      if(data.nrMode){
        this.fileTypes.unshift('Standard phonebook','Extended phonebook','UUS database')
      }else{
        this.fileTypes.unshift('Standard phonebook','SMS database')
      }
    })

  }

  ngOnChanges(changes: SimpleChanges) { }

  public getData() {
    const data = this.fileEditor?.getData()
    return data
  }

  public exitEditMode() {
    this.fileEditor?.exitEditMode()
  }

  public changeFileType(event: any): void {
    this.isLoading = true
    if(this.fileType !== ''){
      this.fileTypeChanged.emit(true)
    }

      if (!this.device) return

      this.fileType = event.detail.toLowerCase().replaceAll(' ', '-')

      if (this.fileType === "fault-log") {
        const faultLog = new FaultLog();

        this.fieldMappingService.FaultCode().subscribe((data: FaultCodeItem[]) => {
          faultLog.faultCodeItem(data)
        })

        this.fieldMappingService.FaultClass().subscribe((data: FaultClassItem[]) => {
          faultLog.faultClassItem(data)
        })
      }

      this._apiService.getFile(this.fileType, this.device).subscribe({
        next: (file) => {
          this.isLoading = false
          this.getSelectedFileType.emit(event.detail.toLowerCase().replaceAll(' ', '-'))
          this.isError = false
          this.file = file
        },
        error: (e) => {
          this.isLoading = false
          this.isError = true
          if(e.status == 0){// This is the 0 = unknown Error
            this.errorMessage = "Unable to process your request. Please try again later or contact the administrator."
            this.openModalForErrorMessage()
          }else {
            if(e.message === 'CANCELED'){ // This means device is unrechable.
              this.jobCanceled.emit(false)
            }else{
              this.errorMessage = `Error occurred while downloading file. Please contact administrator.`
              this.openModalForErrorMessage()
            }
            
          }
          
        }
        
      })
  }
  public onItemClick(event: any): void {
    if (this.editStateService.getEditedState()) {
      const selectedItem = this.elementRef.nativeElement.querySelector('ix-select')
      this.originalSelectedValue = selectedItem.value
    }
  }

  public typeChanged(event: any): void {
    if (this.editStateService.getEditedState()) {
      this.openModal(event)
    } else {
      this.changeFileType(event)
    }
  }

  async showToastMessage() {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: 'Deployment job submitted',
      type: "success",
      autoCloseDelay: 10000
    });
  }

  async openModalForErrorMessage() {
    const instance = await this.modalService.open({
      content: this.customModalForErrorMessage
    })
    instance.htmlElement.addEventListener('keydown',(keyboardEvent: KeyboardEvent) => {});
  }
}
