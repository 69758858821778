<div>
  <div style="display: inline-block; margin-left: 15px">Filter by Date : </div>
  <div class="filter" style="display: inline-block;"><input class="form-control" type="text" [(ngModel)]="selected"
      ngxDaterangepickerMd [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [ranges]="ranges"
      [linkedCalendars]="true" [isInvalidDate]="isInvalidDate" [showClearButton]="true" placeholder="Select date"
      (ngModelChange)="toDisplayCustomDateData()" [locale]="{applyLabel: 'ok', format: 'YYYY-MM-DD'}"/></div>
</div>
<div class="wrapper">
  <ag-grid-angular class="ag-theme-alpine-dark ag-theme-ix" style="height: 100%; width: 100%;" [columnDefs]="columnDefs"
    [rowData]="rowData$" [rowSelection]="'multiple'" [animateRows]="true" [pagination]="pagination"
    [paginationAutoPageSize]="paginationAutoPageSize" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</div>