import { CrmtDecoder } from "./crmt-decoder";
import { CrmtFile } from "./crmt-file";
import { FaultCodeItem, FaultClassItem } from 'src/app/services/field-mapping.service';

export interface FaultLogItem {

    faultCode: string;
    faultClass: string;
    loggedDate: string;
    loggedTime: string;
}

export class FaultLog extends CrmtFile {

    public entries: FaultLogItem[] = [];
    static faultClassItemMap = new Map<string, string>();
    static faultCodeItemMap = new Map<string, string>();

    constructor() {
        super()
    }

    public static fromRawData(buffer: ArrayBuffer): FaultLog {

        const decoder = new CrmtDecoder(buffer);

        // Create the new object
        const thingy = new FaultLog();

        // Extract the actual entries
        const entryCount = decoder.getUint32();
        const firstEntryIndex = decoder.getUint32();

        for (let e = 0; e < entryCount; e++) {
            const faultCode = thingy.checkFaultCodeRange(decoder.getUint16());
            const faultClass = FaultLog.faultClassItemMap.get(decoder.getUint16().toString())!;
            const loggedDate = decoder.getCrDate()
            const loggedTime = decoder.getCrTime();

            thingy.addEntry(
                faultCode,
                faultClass,
                loggedDate,
                loggedTime)
        }
        return thingy;
    }

    public addEntry(
        faultCode: string,
        faultClass: string,
        loggedDate: string,
        loggedTime: string): void {

        const entry: FaultLogItem = {
            faultCode,
            faultClass,
            loggedDate,
            loggedTime
        }

        this.entries.push(entry)
    }

    public checkFaultCodeRange(faultCode: number): string {
        const faultCodeStr = FaultLog.faultCodeItemMap.get(faultCode.toString())!;
        if (faultCodeStr === undefined) {
            return "unrecognised fault code";
        }
        return faultCodeStr;
    }

    public faultCodeItem(faultCodeItemData: FaultCodeItem[]) {
        faultCodeItemData.forEach(item => {
            FaultLog.faultCodeItemMap.set(item.faultCode, item.faultDescription);
        });
    }

    public faultClassItem(faultClassItemData: FaultClassItem[]) {
        faultClassItemData.forEach(item => {
            FaultLog.faultClassItemMap.set(item.faultClassCode, item.faultClassStatus);
        });
    }
}
