import { AttachedSystemsParameters } from "./attached-systems-parameters";
import { CabRadioParameters } from "./cab-radio-parameters";
import { CrmtFile } from "./crmt-file";
import { ExtendedPhonebook } from "./extended-phonebook";
import { FanUsageLog } from "./fan-usage-log";
import { FaultLog } from "./fault-log";
import { PeriodTimerParameters } from "./period-timers-parameters";
import { ResponseActionDatabase } from "./response-action-database";
import { ServiceNumberDatabase } from "./service-number-database";
import { SmsDatabase } from "./sms-database";
import { StandardPhonebook } from "./standard-phonebook";
import { TrainCrewParameters } from "./train-crew-parameters";
import { UusDatabase } from "./uus-database";

export class CrmtFileFactory {
    constructor() { }

    public static create(type: string): CrmtFile {

        let file: CrmtFile = {}
        const header = { name: 'un-named', majorVersion: 0, minorVersion: 0 }

        switch (type) {

            case "standard-phonebook":
                file = new StandardPhonebook(header);
                break;

            case "extended-phonebook":
                file = new ExtendedPhonebook(header);
                break;

            case "sms-database":
                file = new SmsDatabase(header);
                break;

            case "uus-database":
                file = new UusDatabase(header);
                break;

            case "cab-radio-parameters":
                file = new CabRadioParameters(header);
                break;

            case "attached-systems-parameters":
                file = new AttachedSystemsParameters(header);
                break;

            case "train-crew-parameters":
                file = new TrainCrewParameters(header);
                break;

            case "period-timer-parameters":
                file = new PeriodTimerParameters(header);
                break;

            case "service-number-database":
                file = new ServiceNumberDatabase(header);
                break;

            case "response-action-database":
                file = new ResponseActionDatabase(header);
                break;

            case "fault-log":
                file = new FaultLog();
                break;

            case "fan-usage-log":
                file = new FanUsageLog();
                break;

            default:
                console.log("Invalid file type")
        }

        return file
    }

    public static parse(type: string, data: ArrayBuffer): CrmtFile {

        let file: CrmtFile

        switch (type) {

            case "standard-phonebook":
                file = StandardPhonebook.fromRawData(data);
                break;

            case "extended-phonebook":
                file = ExtendedPhonebook.fromRawData(data);
                break;

            case "sms-database":
                file = SmsDatabase.fromRawData(data);
                break;

            case "uus-database":
                file = UusDatabase.fromRawData(data);
                break;

            case "cab-radio-parameters":
                file = CabRadioParameters.fromRawData(data);
                break;

            case "attached-systems-parameters":
                file = AttachedSystemsParameters.fromRawData(data);
                break;

            case "train-crew-parameters":
                file = TrainCrewParameters.fromRawData(data);
                break;

            case "period-timer-parameters":
                file = PeriodTimerParameters.fromRawData(data);
                break;

            case "service-number-database":
                file = ServiceNumberDatabase.fromRawData(data);
                break;

            case "response-action-database":
                file = ResponseActionDatabase.fromRawData(data);
                break;

            case "fault-log":
                file = FaultLog.fromRawData(data);
                break;

            case "fan-usage-log":
                file = FanUsageLog.fromRawData(data);
                break;

            default:
                throw "Unrecognised file type"
        }

        return file
    }
}
