import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CellValueChangedEvent, ColDef, DomLayoutType, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { CrmtNumericEditor } from 'src/app/cell-editors/crmt-numeric-editor.component';
import { PeriodTimerParameters, PeriodTimerParametersItem } from 'src/app/utils/period-timers-parameters';
import { GenericHeaderEditorComponent } from '../generic-header-editor/generic-header-editor.component';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';
import { EditStateService } from 'src/app/services/edit-state.service';

type LocalItem = {
  parameter: string;
  duration: number;
  min?: number;
  max?: number;
}

@Component({
  selector: 'app-period-timer-parameters-editor',
  templateUrl: './period-timer-parameters-editor.component.html',
  styleUrls: ['./period-timer-parameters-editor.component.css']
})
export class PeriodTimerParametersEditorComponent implements OnInit {

  @Input() crmtFile?: PeriodTimerParameters
  @ViewChild(GenericHeaderEditorComponent) headerEditor?: GenericHeaderEditorComponent
  @Input() disableAppGenericHeaderEditorFields:boolean = true

  public rowData!: LocalItem[]
  public enableEditing = false
  public disableEditing = true

  private edited = false
  private gridApi!: GridApi;
  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 10;
  public gridOptions: GridOptions = {
    stopEditingWhenCellsLoseFocus: true,
  };
  public columnDefs: ColDef[] = [
    { 
      headerName: "Parameter", 
      field: 'parameter', 
      resizable: true,
      width: 350 
    },
    { 
      headerName: "Duration", 
      field: 'duration', 
      resizable: false,
      //Uncomment when validation is implemented
      // editable: () => this.enableEditing,
      // cellEditor: CrmtNumericEditor, 
      // cellEditorParams: {
      //   min: 0,
      //   max: 24 * 60 * 60
      // }
    }
  ];

  constructor(
    private readonly permissions: PermissionsService, private editStateService: EditStateService) {}

  ngOnInit() { 

    this.permissions.getUserLevel().subscribe(level => {

      this.enableEditing = level >= UserLevel.STANDARD
      this.disableEditing = !this.enableEditing
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    
    this.crmtFile = changes["crmtFile"].currentValue

    if(this.crmtFile) {
      this.rowData = this.mapDataToTable(this.crmtFile)
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  public editRow() {
    const selection = this.gridApi.getSelectedNodes()
    const index  = selection[0].rowIndex
    this.gridApi!.setFocusedCell(index as number, "duration");
    this.gridApi!.startEditingCell({
      rowIndex: index as number,
      colKey: "duration",
    });
  }

  public onCellValueChanged(e: CellValueChangedEvent<any, any>) {
    this.edited = true
    this.mapTableToData(this.rowData, this.crmtFile!)
    this.editStateService.setEditedState(true);
  }


  public getData() {

    const headerEdited = this.headerEditor?.edited

    return {
      done: this.edited || headerEdited,
      fileType: 'period-timer-parameters',
      fileData: this.crmtFile
    }
  }

  public exitEditMode() {
    this.edited = false
  }

  private mapDataToTable(data: PeriodTimerParameters): LocalItem[] {

    const e = data.entries[0]

    // The SIM password is a string type so it's easier to treat all the numeric
    // values as strings too.
    const tableData = [
      { 
        parameter: "Emergency voice call set-up time", 
        duration: e.emergencyVoiceCallSetupTime, 
      },
      { 
        parameter: "Periodic signal strength interval", 
        duration: e.periodicSignalStrengthInterval, 
        min: 5,
        max: 600
      },
      { 
        parameter: "Periodic diagnostic test interval", 
        duration: e.periodicDiagnosticTestInterval, 
        min: 60,
        max: 86400
      },
      { 
        parameter: "Self tests timeout",
        duration: e.selfTestsTimeout, 
      },
      { 
        parameter: "HMI off timeout", 
        duration: e.hmiOffTimeout, 
        min: 1,
        max: 14400
      },
      { 
        parameter: "Emergency confirmation network timeout", 
        duration: e.emergencyConfirmNetworkTimeout, 
      },
      { 
        parameter: "Digital monitoring interval", 
        duration: e.digitalMonitoringInterval, 
        min: 1,
        max: 600
      },
      { 
        parameter: "TM call set-up timeout", 
        duration: e.tmCallSetupTimeout, 
      },
      { 
        parameter: "PA Audio feed interval", 
        duration: e.paAutioFeedInterval, 
      },
      { 
        parameter: "Transceiver response timeout", 
        duration: e.transceiverResponseTimeout, 
      },
      { 
        parameter: "SIM Cache Delay", 
        duration: e.simCacheDelay, 
      },
      { 
        parameter: "CF No Reply Timeout", 
        duration: e.cfNoReplyTimeout, 
      },
      { 
        parameter: "DSD Initial Delay", 
        duration: e.dsdInitialDelay, 
        min: 1,
        max: 60
      },
      { 
        parameter: "DSD Warning Duration", 
        duration: e.dsdWarningDuration, 
        min: 1,
        max: 60
      },
      { 
        parameter: "HMI Activity Duration", 
        duration: e.hmiActivityDuration, 
      },
      { 
        parameter: "Deregistration Timeout", 
        duration: e.deregistrationTimeout, 
        min: 60,
        max: 86400
      },
      { 
        parameter: "Loudspeaker Test Duration", 
        duration: e.loudspeakerTestDuration, 
        min: 5,
        max: 600
      },
      { 
        parameter: "Handset Test Duration", 
        duration: e.handsetTestDuration, 
        min: 5,
        max: 600
      },
      { 
        parameter: "Public Address Test Duration", 
        duration: e.publicAddresstestDuration, 
        min: 5,
        max: 900
      },
      { 
        parameter: "Follow Me Retry Delay", 
        duration: e.followMeRetryDelay, 
      },
      { 
        parameter: "Power-up Test Result Display Duration", 
        duration: e.powerupTestResultDisplayDuration, 
        min: 5,
        max: 900
      },
      { 
        parameter: "On-demand Test Result Display Duration", 
        duration: e.onDemandTestResultDisplayDuration, 
        min: 3,
        max: 300
      },
      { 
        parameter: "HMI Indication Hang Time", 
        duration: e.hmiIndicationhandTime, 
      },
      { 
        parameter: "Auto-answer Alerting Period", 
        duration: e.autoAnswerAlertingPeriod, 
      },
      { 
        parameter: "Outgoing Fixed Text Message Timeout Note #1", 
        duration: e.outgoingFixedTextMsgTimeoutNote1, 
      },
      { 
        parameter: "Keep Registration Prompt Timeout", 
        duration: e.keepRegistrationPromptTimeout, 
        min: 5,
        max: 1800
      },
      { 
        parameter: "Intercom to Controller Answer Timeout", 
        duration: e.intercomToControllerAnsTimeout, 
        min: 1,
        max: 86400
      },
      { 
        parameter: "Network Loss fault reporting delay", 
        duration: e.networkLossFaultReportingDelay, 
        min: 5,
        max: 1800
      },
      { 
        parameter: "eREC Sector ID request interval", 
        duration: e.eRecSectoridRequestInterval, 
        min: 5,
        max: 1800
      }
    ]

    return tableData
  }

  private mapTableToData(table: LocalItem[], data: PeriodTimerParameters) {

    let i = 0

    data.entries[0].emergencyVoiceCallSetupTime = table[i++].duration
    data.entries[0].periodicSignalStrengthInterval = table[i++].duration
    data.entries[0].periodicDiagnosticTestInterval = table[i++].duration
    data.entries[0].selfTestsTimeout = table[i++].duration
    data.entries[0].hmiOffTimeout = table[i++].duration
    data.entries[0].emergencyConfirmNetworkTimeout = table[i++].duration
    data.entries[0].digitalMonitoringInterval = table[i++].duration
    data.entries[0].tmCallSetupTimeout = table[i++].duration
    data.entries[0].paAutioFeedInterval = table[i++].duration
    data.entries[0].transceiverResponseTimeout = table[i++].duration
    data.entries[0].simCacheDelay = table[i++].duration
    data.entries[0].cfNoReplyTimeout = table[i++].duration
    data.entries[0].dsdInitialDelay = table[i++].duration
    data.entries[0].dsdWarningDuration = table[i++].duration
    data.entries[0].hmiActivityDuration = table[i++].duration
    data.entries[0].deregistrationTimeout = table[i++].duration
    data.entries[0].loudspeakerTestDuration = table[i++].duration
    data.entries[0].handsetTestDuration = table[i++].duration
    data.entries[0].publicAddresstestDuration = table[i++].duration
    data.entries[0].followMeRetryDelay = table[i++].duration
    data.entries[0].powerupTestResultDisplayDuration = table[i++].duration
    data.entries[0].onDemandTestResultDisplayDuration = table[i++].duration
    data.entries[0].hmiIndicationhandTime = table[i++].duration
    data.entries[0].autoAnswerAlertingPeriod = table[i++].duration
    data.entries[0].outgoingFixedTextMsgTimeoutNote1 = table[i++].duration
    data.entries[0].keepRegistrationPromptTimeout = table[i++].duration
    data.entries[0].intercomToControllerAnsTimeout = table[i++].duration
    data.entries[0].networkLossFaultReportingDelay = table[i++].duration
    data.entries[0].eRecSectoridRequestInterval = table[i++].duration
  }
}


