<div class="wrapper">
    <ix-content-header width="100%"
        hasBackButton="false"
        headerTitle="Device Registration"
        headerSubtitle="Register a new device to allow it to connect to CRMT.">
    </ix-content-header>

<app-device-type-selector [fileTypeSelected]="true"
    (deviceType)="deviceTypeChanged($event)">
</app-device-type-selector>
</div>

<div class="wrapper">
    <ix-content-header
        variant="Secondary"
        headerTitle="Device Serial Number"
        headerSubtitle="Enter the serial number of the device to be registered.">
    </ix-content-header>

    <ix-input-group class="register-group" style="margin-bottom: 0.5rem;">
        <div class="grid">
            <ix-layout-grid class="grid-layout">
              <ix-row>
                <ix-col class="grid-col" size="4">
                    <input #serialNumberInput placeholder="Serial number" type="text" class="form-control"
            (change)="serialNumberChanged($event)" />
                </ix-col>
              </ix-row>
              <ix-row>
                <ix-col class="error-col">
                    <div *ngIf="isSerialNumberValid" style="color: red;">
                        Serial Number must be 8 characters long and can only contain letters and numbers.
                        No spaces or special characters are allowed.</div>
                </ix-col>
              </ix-row>
            </ix-layout-grid>
        </div>
    </ix-input-group>
    
    <ix-button 
        [disabled]="disableStandard || invalidData || isSerialNumberValid"
        class="m-1" 
        variant="primary"
        (click)="registerDevice()">
        Register Device
    </ix-button>
</div>  
<ng-template #customModalForErrorMessage>
    <ix-modal>
        <ix-modal-header> {{ errorMessage }} </ix-modal-header>
    </ix-modal>
</ng-template>