import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getCurrentUser, fetchUserAttributes, signInWithRedirect, signOut, fetchAuthSession } from 'aws-amplify/auth';
import { filter, map, Observable, from } from 'rxjs';
import { PermissionsService, UserLevel } from './services/permissions.service';
import { LicenseManager } from "ag-grid-enterprise";
import { environment } from './../environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-061654}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{SIEMENS_MOBILITY_LIMITED}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Cab_Radio_Management_Terminal}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Cab_Radio_Management_Terminal}_need_to_be_licensed___{Cab_Radio_Management_Terminal}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{13_July_2025}____[v3]_[01]_MTc1MjM2MTIwMDAwMA==0c3670a7abc472d12b97aabf3e7c494a")
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'crmt-gui';

  public name$!: Observable<string>;

  public disableStandard = true
  public disableSuper = true
  public isLoading = true;
  public applicationName = environment.applicationName
  public applicationVersion = environment.applicationVersion

  isAuthenticated = false;
  constructor(
    private readonly permissions: PermissionsService,
    private _router: Router,
    private authService: OidcSecurityService) { }

  public ngOnInit(): void {
    
    getCurrentUser().then(user => {
      if (user) {
        fetchUserAttributes().then(attributes => {
          this.name$ = new Observable(observer => {
            observer.next(attributes.given_name);
            observer.complete();
          });
        });
      }
    });

    this.permissions.getUserLevel().subscribe(level => {
      this.disableStandard = level < UserLevel.STANDARD
      this.disableSuper = level < UserLevel.SUPER
      if (this.isAuthorized && level > 0) {
        this.isLoading = false;
      }
    })
  }

  public get isAuthorized(): Observable<boolean> {
    return from(fetchAuthSession().then(() => true).catch(() => false));
  }

  public async signIn(): Promise<void> {
    this.authService.authorize();
  }

  public async signOut(): Promise<void> {
    if (window.sessionStorage) {
      window.sessionStorage.clear();
    }
    window.location.href = "https://eu-west-2km7nancwq.auth.eu-west-2.amazoncognito.com/logout?client_id=abnmpnmlr9fchklijieajelpg&logout_uri=crmt-original.64836216846.com/login/callback/"; 
  }
}
