import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NewOrganisationData } from 'src/app/components/create-group/create-group.component';
import { FleetTreeComponent } from 'src/app/components/fleet-tree/fleet-tree.component';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-fleet-page',
  templateUrl: './fleet-page.component.html',
  styleUrls: ['./fleet-page.component.css']
})
export class FleetPageComponent implements OnInit {

  @ViewChild(FleetTreeComponent) fleetTree?: FleetTreeComponent;

  public contentSubtitle = "Organise your devices";

  constructor(private readonly permissions: PermissionsService) { }

  ngOnInit(): void {
    this.permissions.getUserLevel().subscribe(level => {
      if(level >= UserLevel.PRIVILEGED){
        this.contentSubtitle = "Organise your devices and allocate them to organisations";
      }
    })
  }

  newOrganizationHandler(newOrganisationData: NewOrganisationData) {

    this.fleetTree?.addOrganization(newOrganisationData)
  }

  newSubgroupHandler(event: any) {

    this.fleetTree?.addSubgroup(event)
  }
}
