<div class="wrapper">
    <ix-content-header width="100%"
        hasBackButton="false"
        headerTitle="Jobs List"
        headerSubtitle="Lists all the deployment jobs">
    </ix-content-header>

    <app-crmt-job-list
        [refreshed]="refreshed"
        (selectedJob)="selectedJob($event)"
        (selectedForCancellation)="selectedJobForCancellation($event)"
        (refreshCompleted)="onRefreshCompleted()"
    ></app-crmt-job-list>

    <div class="button-panel">
        <ix-button 
            class="m-1" 
            variant="Primary" 
            [disabled]="cancelJobId.length === 0"
            (click)="cancelJob()">
            Cancel Job
        </ix-button>
        <ix-button 
            [disabled]="refreshed"
            class="m-1" 
            variant="Primary" 
            [outline]="true"
            (click)="refreshJobList()">
            Refresh Job List
        </ix-button>
  
    </div>

    <div *ngIf="jobId">
        <ix-content-header
            class="mid-page"
            width="100%"
            hasBackButton="false"
            headerTitle="Job Details"
            [headerSubtitle]="'Live update of job ' + jobId">
        </ix-content-header>

        <app-crmt-job-status
            [jobId]="jobId">
        </app-crmt-job-status>
    </div>
</div>  
<ng-template #customModalForErrorMessage>
    <ix-modal>
        <ix-modal-header> {{ errorMessage }} </ix-modal-header>
    </ix-modal>
</ng-template>