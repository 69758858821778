import { CrmtDecoder } from "./crmt-decoder";
import { CrmtEncoder } from "./crmt-encoder";
import { CrmtFile, CrmtHeader, ICrmtFile } from "./crmt-file";
import { environment } from './../../environments/environment';

export interface TrainCrewParametersItem {

    crewMember : string;
    present: number;
    registrationRequiredFlag: number;
    functionCode: string;
    descriptionReference: number,
    presentStr: string,
    registrationRequiredFlagStr: string
}

export class TrainCrewParameters extends CrmtFile implements ICrmtFile {

    public entries: TrainCrewParametersItem[] = [];
    public static crewMembers = environment.configs.trainCrewParametersCrewMemberValues;

    constructor(public header: CrmtHeader) {
        super()
    }

    public static fromRawData(buffer: ArrayBuffer): TrainCrewParameters {
        
        const decoder = new CrmtDecoder(buffer);

        // // Extract the common header values
        const header = decoder.getHeader()

        // Create the new object
        const thingy = new TrainCrewParameters(header);

        // Extract the actual entries
        const entryCount = decoder.getUint32();

        for(let e = 0; e < entryCount; e++) {
            const crewMember = e < TrainCrewParameters.crewMembers.length ? TrainCrewParameters.crewMembers[e] : "" ;
            const present = decoder.getUint16(); 
            const registrationRequiredFlag = decoder.getUint16(); 
            const functionCode = decoder.getFixedLengthAscii(2);
            const descriptionReference = decoder.getUint32();
        
            thingy.addEntry(
                crewMember,
                present,
                registrationRequiredFlag,
                functionCode,
                descriptionReference
            );
        }

        return thingy;
    }

    public toRawData(): ArrayBuffer {

        const encoder = new CrmtEncoder(10 * 1024)

        // Create the header
        encoder.setHeader(this.header)

        // Write the actual entries
        const entryCount = this.entries.length
        encoder.setUint32(entryCount);

        const entries: TrainCrewParametersItem[] = []

        for(let e = 0; e < entryCount; e++) {

            const entry = this.entries[e]
            let registrationRequiredFlag = entry.registrationRequiredFlagStr === 'Yes' ? 1 : 0 
            let presentFlag = entry.presentStr  === 'Yes' ? 1 : 0 
            encoder.setUint16(presentFlag)
            encoder.setUint16(registrationRequiredFlag)
            encoder.setFixedLengthAscii(2, entry.functionCode)
            encoder.setUint32(entry.descriptionReference)
        }

        return encoder.getFileData()
    }

    public addEntry(
        crewMember : string,
        present: number,
        registrationRequiredFlag: number,
        functionCode: string,
        descriptionReference: number): void {
        let presentStr = present === 0 ? 'No' : 'Yes';    
        let registrationRequiredFlagStr = registrationRequiredFlag == 0 ? 'No' : 'Yes';
        const entry: TrainCrewParametersItem = {
            crewMember,
            present,
            registrationRequiredFlag,
            functionCode,
            descriptionReference,
            presentStr,
            registrationRequiredFlagStr
        }    

        this.entries.push(entry)
    }
}

