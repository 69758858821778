<ix-content-header
    variant="Secondary"
    headerTitle="Fan Usage Log Viewer"
    headerSubtitle="View the fan usage log">
</ix-content-header>

<ag-grid-angular
    class="ag-theme-alpine-dark ag-theme-ix"
    [columnDefs]="columnDefs"
    [rowData]="rowData"
    [rowSelection]="'single'"
    [animateRows]="true"
    [domLayout]="domLayout"
    [pagination]="pagination"
    [paginationPageSize]="paginationPageSize">
</ag-grid-angular>
