export const environment = {
  production: false,
  applicationName: require('../../../crmt-gui/package.json').name,
  applicationVersion: require('../../../crmt-gui/package.json').version,
  
  cognitoConfig: {
    userPoolId: 'eu-west-2_Km7nANcwQ',
    userPoolWebClientId: 'abnmpnmlr9fchklijieajelpg',
    redirectUri: window.location.origin + '/login/callback'
  },

  backendConfig: {
    url: "https://crmt-original-api.64836216846.com/"
  },
  configs:{
    attachedSystemsParametersSystemValues : ["Not Used","Intercom","Public Address","ERTMS/ETCS","Train-borne Recorder","Not Used","Not Used","Not Used"],
    trainCrewParametersCrewMemberValues : ["Driver 2","Driver 3","Driver 4","Driver 5","Chief Conductor","Chief Caterer","Chief Security","Departure Procedure Lead Driver","Departure Procedure Chief Conductor"]
  }
};
